import Card from '@UIKit/Card/Card';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '@/components/Utilities/Tabs';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import { useRetryRunInfo } from '@/hooks/useRetryRunInfo';
import { ExerciseContext } from '@/contexts/ExerciseContext';
import { UserActionFeedbacks } from '@/components/Feedback/FeedbackDetail/UserActionFeedbacks';

const RetryRun = () => {
  const { setStep, ExerciseGraph, jsonGraph, ExerciseSessionHistory } = useContext(ExerciseContext);
  const { RerunNodeID } = useParams();
  const { rerunSceneNode } = useRetryRunInfo({
    exerciseSessionHistory: ExerciseSessionHistory,
    exerciseGraph: ExerciseGraph,
    rerunNodeID: RerunNodeID
  });

  const navigate = useNavigate();

  const getLastEventByType = (events, eventType) => {
    for (let i = events.length - 1; i >= 0; i--) {
      if (events[i].EventType === eventType) {
        return events[i];
      }
    }
    return undefined;
  };

  const getRecommendationsByRerunNodeID = (rerunNodeID, detailedFeedbacks) => {
    for (const act of detailedFeedbacks) {
      if (act.userActions) {
        for (const userAction of act.userActions) {
          if (userAction.rerunNodeID === Number(rerunNodeID)) {
            return userAction.recommendations || [];
          }
        }
      }
    }
    // Return an empty array if no recommendations are found
    return [];
  };

  const lastDetailedFeedbacksEvent = getLastEventByType(ExerciseSessionHistory, 'DetailedFeedbacks')
    .Content.DetailedFeedbacks.solvedActsFeedbacks;

  const exit = () => {
    window.sdk.event().emit('quitExercise');
    navigate('/');
  };

  const startScenario = () => {
    window.sdk.event().emit('startExercise');
    setStep('scenario');
  };

  const objectives = rerunSceneNode.Objectives || jsonGraph.IntroPanel.Objectives;
  const context = rerunSceneNode.Context || jsonGraph.IntroPanel.Context;

  return (
    <>
      <div className="exercise-intro">
        <div className="mx-auto w-5/6 md:flex md:space-x-6">
          <Card className="md:flex-1">
            <Card.Title>Pour votre prochaine intervention, essayez ces techniques :</Card.Title>
            <div className="h-[300px]">
              <UserActionFeedbacks
                className={`mb-auto mt-4 h-full`}
                descriptionClassName="max-h-[170px] overflow-y-scroll"
                isStandalone={true}
                feedbacks={getRecommendationsByRerunNodeID(RerunNodeID, lastDetailedFeedbacksEvent)}
              />
            </div>
          </Card>
          <Card className="max-md:mt-6 md:flex-1">
            <div className="exercise-intro-retry-content">
              <Tabs
                tabs={[
                  {
                    key: 'context',
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(
                            jsonGraph.IntroPanel.ContextLabel.replace(/<[^>]*>/g, '')
                          )
                        }}
                      />
                    ),
                    content: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(`<div class="exercise-intro-desc">${context}</div>`)
                        }}
                      />
                    )
                  },
                  {
                    key: 'objectives',
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(
                            jsonGraph.IntroPanel.ObjectivesLabel.replace(/<[^>]*>/g, '')
                          )
                        }}
                      />
                    ),
                    content: (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(
                              `<div class="exercise-intro-desc">
                                ${objectives}
                                </div>`
                            )
                          }}
                        />
                      </>
                    )
                  }
                ]}
              />
            </div>
          </Card>
        </div>
      </div>
      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'intro-exercise'}>
        <button id="briefing-next-button" className="cta cta--white" onClick={exit}>
          <Translation keyName="general.quit">Quitter</Translation>
        </button>
        <button
          id="briefing-next-button"
          data-testid="start-exercise-button"
          className="cta cta--accent"
          onClick={startScenario}>
          <Translation keyName="general.start_exercise">Commencer l'exercice</Translation>
        </button>
      </BottomBar>
    </>
  );
};
export default RetryRun;
