import React from 'react';
import '@/styles/components/Exercise.scss';
import { useParams } from 'react-router-dom';
import { ExerciseContextProvider } from '@/contexts/ExerciseContext';
import LegacyVisioExercise from '@/components/VisioExercise/LegacyVisioExercise';

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class LegacyExercisePage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.sdk.event().emit('ExercisePanelEntered');
    }, 1000);
  }

  render() {
    return (
      <>
        <input type="hidden" id="fakeAudioSession" value="" />
        <audio
          id="fakeAudioSessionPlayer"
          crossOrigin="anonymous"
          src=""
          playsInline
          style={{ display: 'none' }}></audio>
        <ExerciseContextProvider>
          <LegacyVisioExercise
            StepLabel={this.props.match.params.step}
            ExerciseID={this.props.exerciseId}
            RerunNodeID={this.props.match.params.RerunNodeID || null}
            ExerciseSessionID={this.props.match.params.ExerciseSessionID || null}
          />
        </ExerciseContextProvider>
      </>
    );
  }
}

export default withRouter(LegacyExercisePage);
