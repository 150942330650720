import log from 'loglevel';
import { CounterTypes, CounterScopes } from './CounterTypes';

export default class Counters {
  private Graph;
  private CountersValues: { [key: string]: number } = {};

  constructor(iGraph: any) {
    this.Graph = iGraph;
  }

  Reset() {
    this.CountersValues = {};
  }

  SetValue(iName: string, iValue: number) {
    if (!iName) {
      log.error(`Counters.setValue: '${iName}' is not a valid name.`);
      return;
    }
    this.CountersValues[iName] = iValue;
  }

  GetValue(iName: string): number {
    if (this.CountersValues[iName] === undefined) {
      this.SetValue(iName, 0);
    }
    return this.CountersValues[iName];
  }

  IncrementCounters(iCountersNamesList: string[], iLogInHistory = true) {
    // Ensure the counters names list is valid
    if (
      !iCountersNamesList ||
      !Array.isArray(iCountersNamesList) ||
      iCountersNamesList.length === 0
    ) {
      log.debug(
        `Counters.IncrementCounters: counters names list '${iCountersNamesList}' is empty or invalid: `,
        iCountersNamesList
      );
      return;
    }

    // Increment each counter
    iCountersNamesList.forEach((iCounterName) => {
      if (!iCounterName) {
        log.error(`Counters.IncrementCounters: '${iCounterName}' is not a valid name.`);
        return;
      }
      const newValue = this.GetValue(iCounterName) + 1;
      this.SetValue(iCounterName, newValue);
    });

    // Log history event
    if (iLogInHistory) {
      this.Graph.History.AddCountersIncrementation(iCountersNamesList);
    }
  }

  private IncrementGlobalCounter(iType: string, iID: string) {
    const counterName = iID
      ? `${CounterScopes.Global}${iType}Counter_${iID}`
      : `${CounterScopes.Global}${iType}Counter`;
    this.IncrementCounters([counterName]);
  }

  private IncrementLocalCounter(iType: string, iID: string, iBranchingDecisionNodeName: string) {
    const counterName = iID
      ? `${CounterScopes.Local}${iType}Counter_${iBranchingDecisionNodeName}_${iID}`
      : `${CounterScopes.Local}${iType}Counter_${iBranchingDecisionNodeName}`;
    this.IncrementCounters([counterName]);
  }

  IncrementUserActionsCounters(iUserActionsIDs: string[], iBranchingDecisionNode: any) {
    const userActionsSpecificCountersIDs = new Set<string>();

    for (const userActionID of iUserActionsIDs) {
      // Increment global and local counters for this user action
      this.IncrementGlobalUACounter(userActionID);
      this.IncrementLocalUACounter(userActionID, iBranchingDecisionNode.BranchingDecisionName);

      // List specific counters to increment for this user action and make sure they are unique
      const userActionData = this.Graph.GetFullUserActionData(
        userActionID,
        iBranchingDecisionNode.ID,
        iBranchingDecisionNode
      );
      if (!userActionData.SpecificCounters) {
        continue;
      }
      for (const specificCounterID of userActionData.SpecificCounters) {
        userActionsSpecificCountersIDs.add(specificCounterID);
      }
    }

    // Increment each specific counter found in the user actions data
    this.IncrementCounters([...userActionsSpecificCountersIDs]);
  }

  IncrementGlobalUACounter(iUserActionID: string) {
    this.IncrementGlobalCounter(CounterTypes.UserAction, iUserActionID);
  }

  IncrementLocalUACounter(iUserActionID: string, iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(CounterTypes.UserAction, iUserActionID, iBranchingDecisionNodeName);
  }

  IncrementUserActionFeedbacksCounters(
    iUserActionFeedbacksIDs: string[],
    iBranchingDecisionNode: any
  ) {
    for (const iUserActionFeedbackID of iUserActionFeedbacksIDs) {
      // Increment global and local counters for this user action feedback
      this.IncrementGlobalUAFCounter(iUserActionFeedbackID);
      this.IncrementLocalUAFCounter(
        iUserActionFeedbackID,
        iBranchingDecisionNode.BranchingDecisionName
      );
    }
  }

  IncrementGlobalUAFCounter(iUserActionFeedbackID: string) {
    this.IncrementGlobalCounter(CounterTypes.UserActionFeedback, iUserActionFeedbackID);
  }

  IncrementLocalUAFCounter(iUserActionFeedbackID: string, iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(
      CounterTypes.UserActionFeedback,
      iUserActionFeedbackID,
      iBranchingDecisionNodeName
    );
  }

  IncrementGoodActionsCounters(iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(CounterTypes.GoodActions, '', iBranchingDecisionNodeName);
    this.IncrementGlobalCounter(CounterTypes.GoodActions, '');
  }

  IncrementBadActionsCounters(iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(CounterTypes.BadActions, '', iBranchingDecisionNodeName);
    this.IncrementGlobalCounter(CounterTypes.BadActions, '');
  }

  IncrementFailActionsCounters(iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(CounterTypes.FailActions, '', iBranchingDecisionNodeName);
    this.IncrementGlobalCounter(CounterTypes.FailActions, '');
  }

  IncrementNeutralActionsCounters(iBranchingDecisionNodeName: string) {
    this.IncrementLocalCounter(CounterTypes.NeutralActions, '', iBranchingDecisionNodeName);
    this.IncrementGlobalCounter(CounterTypes.NeutralActions, '');
  }

  GetAllValues(): { [key: string]: number } {
    return { ...this.CountersValues };
  }
}
