import log from 'loglevel';
import SmartBranchingDecision from '../ExerciseNodes/SmartBranchingDecision';
import UserActionsEvaluator from '../UserActionsEvaluator';
import SpeechPartsHighlight from '../SpeechPartsHighlight';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import { FEEDBACK_EVALUATIONS } from '../Solvers/DetailedFeedbacksSolver';

export default class PromptBranchingDecision extends SmartBranchingDecision {
  m_UserActionEvaluator = null;

  // BranchingDecision Results
  m_BranchingDecisionResults = {}; // Stores the final results of the user actions analysis.
  m_DetectedUserActionsInfos = []; // Only used before m_BranchingDecisionResults.DetectedUserActions is filled. Stores the detected user actions IDs found from the detection.
  m_ComposedUserActionsInfos = []; // Only used before m_BranchingDecisionResults.DetectedUserActions is filled. Stores the user actions IDs added through the composed user actions solver.
  m_UserActionsFeedbacksInfos = []; // Only used before m_BranchingDecisionResults.DetectedUserActions is filled. Stores the total user actions feedbacks IDs found from the all triggered user actions (detected and composed).
  m_TotalUserActionsInfos = []; // Only used before m_BranchingDecisionResults.DetectedUserActions is filled. Stores the final user actions IDs after merging and filtering operations.
  m_FinalUserActionsFeedbacksInfos = []; // Only used before m_BranchingDecisionResults.DetectedUserActions is filled. Stores the final user actions feedbacks after merging and filtering operations. Infos structure: {ID, ShouldForceMissedOpportunity}
  m_ForcedUserActionsIDs = []; // Stores the forced user actions IDs to use in force user actions mode.

  // Stores the needed data for the speech parts highlighter.
  m_SpeechPartsToHighlightData = {
    detectedUserActionsData: {},
    composedUserActionsData: {},
    userActionFeedbacksData: {}
  };

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    if (iProperties.EvaluatorRules && iProperties.EvaluatorRules.length) {
      // since the rules are stored as strings in the graph, they need to be parsed
      try {
        const parsedRules = iProperties.EvaluatorRules.map((rule) => JSON.parse(rule));
        this.m_UserActionEvaluator = new UserActionsEvaluator(parsedRules);
      } catch (error) {
        throw `PromptBranchingDecision: Failed to parse evaluator rules : ${iProperties.EvaluatorRules} at node ${this.ID}`;
      }
    }

    this.AvailableUserActions = this.MergeAvailableUserActionsData(
      iProperties.AvailableUserActions
    );
    this.AvailableUserActionsFeedbacks = this.MergeAvailableUserActionsFeedbacksData(
      iProperties.AvailableUserActionsFeedbacks
    );
  }

  MergeAvailableUserActionsData(iAvailableUserActions) {
    const mergedAvailableUserActions = {};

    for (const uaID of Object.keys(iAvailableUserActions)) {
      mergedAvailableUserActions[uaID] = this.Graph.GetFullUserActionData(uaID, this.ID, this);
    }

    return mergedAvailableUserActions;
  }

  MergeAvailableUserActionsFeedbacksData(iAvailableUserActionsFeedbacks) {
    const mergedAvailableUserActionsFeedbacks = {};

    for (const uafID of Object.keys(iAvailableUserActionsFeedbacks)) {
      mergedAvailableUserActionsFeedbacks[uafID] = this.Graph.GetFullUserActionFeedbackData(
        uafID,
        this.ID,
        this
      );
    }

    return mergedAvailableUserActionsFeedbacks;
  }

  async ExecuteAnalysis() {
    const thisAnalysisCounter = this.m_AnalysisCounter;
    log.debug(
      this.GetIdentity() +
        '.ExecuteAnalysis: Asking GPT for the ' +
        thisAnalysisCounter +
        'th time.'
    );

    // Initialize result
    this.ResetResults();
    let userActionsDetectionResult = {
      status: 'failed',
      request: '',
      answer: '',
      branch: null,
      possibleBranches: JSON.stringify(this.Branches)
    };

    // If in force user actions mode, do not ask GPT and wait for forced user actions
    if (window.testMode.forceUserActionsMode) {
      userActionsDetectionResult.status = 'success';
      userActionsDetectionResult.request = 'DEBUG';
      userActionsDetectionResult.answer = 'DEBUG';
      await this.WaitForForcedUserActions();
      userActionsDetectionResult.answer = this.m_ForcedUserActionsIDs;
    } else {
      // Ask user actions to GPT
      let userActionAnswer = null;
      const detectionInputData = {
        conversation: this.m_PreviousConversation,
        speech: this.m_Speech,
        exerciseId: this.Graph.ExerciseID,
        nodeId: this.ID
      };

      try {
        userActionAnswer = await window.sdk
          .openaiAPI()
          .CallDetectInteractionUserActionsAPI(
            detectionInputData.speech,
            detectionInputData.conversation,
            detectionInputData.exerciseId,
            detectionInputData.nodeId,
            this.DatabaseID
          );
      } catch (error) {
        if (error.name === 'AbortError') {
          throw error;
        } else {
          log.error(this.GetIdentity() + '.ExecuteAnalysis: Request failed:', error);
          userActionsDetectionResult.status = 'failed';
          return userActionsDetectionResult;
        }
      }

      // Log a warning for all failed UserAction detection queries
      for (let key of userActionAnswer.detection.failed) {
        log.warn(this.GetIdentity() + `.ExecuteAnalysis: User action detection failed for ${key}.`);
      }

      // Extract all positively detected UserAction IDs
      const userActionsIDs = Object.keys(userActionAnswer.detection.results).filter(
        (key) => userActionAnswer.detection.results[key] === true
      );

      // Update old analysis tasks status to "ignored" if existing
      if (this.previousUAAnalysisTaskID) {
        window.sdk.AnalysisTask().updateOne(this.DatabaseID, this.previousUAAnalysisTaskID, {
          AnalysisStatus: 'ignored',
          AnalyzerEngine: 'GPTUserAction'
        });
      }
      this.previousUAAnalysisTaskID = userActionAnswer.detection.analysisTaskID;

      // Prepare ouput result
      userActionsDetectionResult.analysisTaskID = userActionAnswer.detection.analysisTaskID;
      userActionsDetectionResult.answer = userActionsIDs;
      userActionsDetectionResult.status = 'success';
      userActionsDetectionResult.request = JSON.stringify(detectionInputData);

      if (userActionsIDs.length === 0) {
        log.debug(
          this.GetIdentity() +
            `.ExecuteAnalysis: No user actions detected. (Request counter ${thisAnalysisCounter})`
        );
      } else {
        log.debug(
          this.GetIdentity() +
            `.ExecuteAnalysis: User Action detection result =\n${userActionsDetectionResult.answer
              .map((uaID) => this.AvailableUserActions[uaID].PromptName)
              .join('\n')}\n(Request counter ${thisAnalysisCounter})`
        );
      }
    }

    // Compute final results from detected user actions
    this.m_BranchingDecisionResults = this.ComputeBDResultsFromDetectedUAs(
      userActionsDetectionResult.answer
    );

    // Still accept to use the duplicate legacy userActionsDetectionResult object for S1 compatibility
    userActionsDetectionResult.status = 'success';
    userActionsDetectionResult.branch = this.m_BranchingDecisionResults.BranchToTrigger;
    userActionsDetectionResult.prioritaryUserAction =
      this.m_BranchingDecisionResults.PrioritaryUserAction.ID;

    log.debug(
      `${this.GetIdentity()}.ExecuteAnalysis: Finished!\n` +
        `Chosen branch =\n${JSON.stringify(userActionsDetectionResult.branch, null, 1)}\n` +
        `From user action =\n"${userActionsDetectionResult.prioritaryUserAction}"\n` +
        `Detected user actions =\n${JSON.stringify(this.m_TotalUserActionsInfos, null, 1)}\n` +
        `(Request counter ${thisAnalysisCounter})`
    );

    return userActionsDetectionResult;
  }

  async WaitForForcedUserActions() {
    log.debug(this.GetIdentity() + '.WaitForForcedUserActions: Waiting for forced user actions.');
    while (this.m_ForcedUserActionsIDs.length === 0) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
  }

  // Save detected user actions and solve the composed user actions, user actions feedbacks and compute final results
  ComputeBDResultsFromDetectedUAs(iDetectedUserActionIDs) {
    // Warn if m_DetectedUserActionsIDs has already been set
    if (this.m_DetectedUserActionsInfos.length > 0) {
      log.warn(
        `${this.GetIdentity()}.ComputeBDResultsFromDetectedUAs: Detected user actions already set from a previous detection! Do we have a sync issue?`
      );
    }

    // Save detected user actions before solving composed user actions to log them to DynamoDB
    this.m_DetectedUserActionsInfos = this.GetDetectedUserActionsInfos(iDetectedUserActionIDs);
    this.m_TotalUserActionsInfos = [...this.m_DetectedUserActionsInfos];
    this.m_ComposedUserActionsInfos = this.SolveComposedAndDefaultUserActions();
    this.m_TotalUserActionsInfos.push(...this.m_ComposedUserActionsInfos);
    this.m_UserActionsFeedbacksInfos = this.SolveUserActionsFeedbacks();
    this.m_MissedOpportunities = this.DetectMissedOpportunities();

    return this.ComputeFinalResults();
  }

  GetDetectedUserActionsInfos(iDetectedUserActionIDs) {
    let detectedUserActionsInfos = [];

    for (let userActionID of iDetectedUserActionIDs) {
      let userAction = this.AvailableUserActions[userActionID] || null;

      if (!userAction) {
        log.error(
          `${this.GetIdentity()}.GetDetectedUserActionsInfos: User action ${userActionID} not found in AvailableUserActions.`
        );
        continue;
      }

      detectedUserActionsInfos.push({
        ID: userAction.ID,
        PriorityRank: userAction.PriorityRank
      });
    }

    return detectedUserActionsInfos;
  }

  SolveComposedAndDefaultUserActions() {
    // List the composed user actions IDs found to log them to DynamoDB
    let composedUserActionsInfos = [];

    if (this.m_UserActionEvaluator && this.m_UserActionEvaluator.rules.length > 0) {
      // Get user actions from rules
      const detectedUserActionsIDs = this.m_DetectedUserActionsInfos.map((item) => item.ID);
      const rulesResults =
        this.m_UserActionEvaluator.getUserActionIDsFromRules(detectedUserActionsIDs);

      // Save composed user actions data for speech highlight
      this.m_SpeechPartsToHighlightData.composedUserActionsData = rulesResults;

      // Add user actions to the list of detected user actions
      for (const userActionID of Object.keys(rulesResults)) {
        let userAction = this.AvailableUserActions[userActionID] || null;

        if (!userAction) {
          log.error(
            `${this.GetIdentity()}.SolveComposedAndDefaultUserActions: User action ${userActionID} not found in AvailableUserActions.`
          );
          continue;
        }

        composedUserActionsInfos.push({
          ID: userAction.ID,
          PriorityRank: userAction.PriorityRank,
          ContributingActionIDs: rulesResults[userActionID].contributingActionIDs
        });
      }
    }

    // Try to apply default user action if no user action is detected
    if (
      this.m_DetectedUserActionsInfos.length === 0 &&
      composedUserActionsInfos.length === 0 &&
      this.DefaultUserActionID
    ) {
      const defaultUserAction = this.AvailableUserActions[this.DefaultUserActionID] || null;
      if (!defaultUserAction) {
        log.error(
          `${this.GetIdentity()}.SolveComposedAndDefaultUserActions: Default user action ${
            this.DefaultUserActionID
          } not found in AvailableUserActions.`
        );
      } else {
        composedUserActionsInfos.push({
          ID: defaultUserAction.ID,
          PriorityRank: defaultUserAction.PriorityRank
        });
      }
    }

    // Log composed user actions analysis to DynamoDB
    this.LogComposedUserActionsAnalysisToDynamoDB(
      this.m_DetectedUserActionsInfos.map((item) => item.ID),
      composedUserActionsInfos.map((item) => item.ID)
    );

    return composedUserActionsInfos;
  }

  SolveUserActionsFeedbacks() {
    let userActionsFeedbacksInfos = [];

    // Add user actions feedbacks to the list of detected user actions feedbacks
    for (let userActionInfos of this.m_TotalUserActionsInfos) {
      let userAction = this.AvailableUserActions[userActionInfos.ID] || null;

      if (!userAction) {
        log.error(
          `${this.GetIdentity()}.SolveUserActionsFeedbacks: User action ${
            userActionInfos.ID
          } not found in AvailableUserActions.`
        );
        continue;
      }

      const userActionFeedbackID = userAction.LinkedUserActionFeedback;
      if (!userActionFeedbackID) {
        continue;
      }

      const userActionFeedback = this.AvailableUserActionsFeedbacks[userActionFeedbackID] || null;
      if (!userActionFeedback) {
        log.error(
          `${this.GetIdentity()}.SolveUserActionsFeedbacks: User action feedback ${userActionFeedbackID} not found in AvailableUserActionsFeedbacks.`
        );
        continue;
      }

      // Save user action feedback
      const userActionFeedbackInfo = userActionsFeedbacksInfos.find(
        (item) => item.ID === userActionFeedbackID
      );
      if (!userActionFeedbackInfo) {
        userActionsFeedbacksInfos.push({
          ID: userActionFeedbackID,
          SourceUserActionsIDs: [userActionInfos.ID],
          PriorityRank: userActionFeedback.PriorityRank,
          Tags: userActionFeedback.Tags,
          IsToast: userActionFeedback.IsToast,
          MissedOpportunity: false // Set later, on MissedOpportunities detection
        });
      } else {
        userActionFeedbackInfo.SourceUserActionsIDs.push(userActionInfos.ID);
      }
    }

    return userActionsFeedbacksInfos;
  }

  DetectMissedOpportunities() {
    let missedOpportunities = [];

    // Two possibilities of missed opportunities :
    // 1. One of the UAs has "ShouldForceMissedOpportunity":true
    for (let userActionInfos of this.m_TotalUserActionsInfos) {
      let userAction = this.AvailableUserActions[userActionInfos.ID] || null;

      if (!userAction) {
        log.error(
          `${this.GetIdentity()}.DetectMissedOpportunities: User action ${
            userActionInfos.ID
          } not found in AvailableUserActions.`
        );
        continue;
      }

      // If user action has "ShouldForceMissedOpportunity":true, it is missed
      if (!userAction.ShouldForceMissedOpportunity) {
        continue;
      }

      if (!userAction.LinkedUserActionFeedback) {
        log.error(
          `${this.GetIdentity()}.DetectMissedOpportunities: User action ${
            userActionInfos.ID
          } has "ShouldForceMissedOpportunity":true but no LinkedUserActionFeedback.`
        );
        continue;
      }

      log.debug(
        `${this.GetIdentity()}.DetectMissedOpportunities: User action ${
          userAction.ID
        } has triggered a missed opportunity on UAF ${userAction.LinkedUserActionFeedback}`
      );

      // Add missed opportunity to the list
      missedOpportunities.push(userAction.LinkedUserActionFeedback);
    }

    // 2. One of the UAFs has "OpportunityAction":true and is not in the list of triggered UAFs
    for (let userActionFeedback of Object.values(this.AvailableUserActionsFeedbacks)) {
      if (userActionFeedback.OpportunityAction !== true) {
        continue;
      }

      // If userActionFeedback is not present in triggered UserActionsFeedbacks, it is missed
      if (!this.m_UserActionsFeedbacksInfos.find((item) => item.ID === userActionFeedback.ID)) {
        log.debug(
          this.GetIdentity() +
            '.DetectMissedOpportunities: Missed opportunity = ' +
            userActionFeedback.ID
        );

        // Add missed opportunity to the list
        missedOpportunities.push(userActionFeedback.ID);
      }
    }

    // Apply found missed opportunities on user actions feedbacks
    for (let missedOpportunityID of missedOpportunities) {
      // Search for the missed UAF in m_UserActionsFeedbacksInfos, if not found create it with missed opportunity tag, if found update it
      let userActionsFeedbacksInfo = this.m_UserActionsFeedbacksInfos.find(
        (item) => item.ID === missedOpportunityID
      );

      const userActionFeedback = this.AvailableUserActionsFeedbacks[missedOpportunityID];
      if (!userActionFeedback) {
        log.error(
          `${this.GetIdentity()}.DetectMissedOpportunities: User action feedback ${missedOpportunityID} not found in AvailableUserActionsFeedbacks.`
        );
        continue;
      }

      if (!userActionsFeedbacksInfo) {
        userActionsFeedbacksInfo = {
          ID: missedOpportunityID,
          SourceUserActionsIDs: [],
          PriorityRank: userActionFeedback.PriorityRank,
          Tags: userActionFeedback.Tags,
          IsToast: userActionFeedback.IsToast,
          MissedOpportunity: true
        };
        this.m_UserActionsFeedbacksInfos.push(userActionsFeedbacksInfo);
      } else {
        userActionsFeedbacksInfo.MissedOpportunity = true;
      }
    }

    return missedOpportunities;
  }

  ComputeFinalResults() {
    let branchingDecisionResults = {};

    // Save UserActions and all computed data
    branchingDecisionResults.DetectedUserActions = [...this.m_DetectedUserActionsInfos];
    branchingDecisionResults.ComposedUserActions = [...this.m_ComposedUserActionsInfos];
    branchingDecisionResults.UserActions = [...this.m_TotalUserActionsInfos];
    branchingDecisionResults.UserActionsFeedbacks = [...this.m_UserActionsFeedbacksInfos];
    branchingDecisionResults.MissedOpportunities = [...this.m_MissedOpportunities];

    // Find the most prioritary (from PriorityRank) user action from m_TotalUserActionsInfos
    // Sort m_TotalUserActionsInfos by PriorityRank
    branchingDecisionResults.UserActions.sort((a, b) => {
      return b.PriorityRank - a.PriorityRank;
    });

    // Get the most prioritary user action
    branchingDecisionResults.PrioritaryUserAction = this.AvailableUserActions[branchingDecisionResults.UserActions[0].ID];

    // Get the branch to trigger from the most prioritary user action
    branchingDecisionResults.BranchToTrigger =
      this.Branches[branchingDecisionResults.PrioritaryUserAction.BranchID];
    // If no user actions feedbacks detected, save empty UAF lists and skip
    if (branchingDecisionResults.UserActionsFeedbacks.length === 0) {
      branchingDecisionResults.Evaluation = 'NEUTRAL';
      branchingDecisionResults.FinalUserActionsFeedbacks = [];
      branchingDecisionResults.PrioritaryUserActionFeedback = null;
      log.debug(
        `${this.GetIdentity()}.ComputeFinalResults: No user actions feedbacks detected, skipping final results computation.`
      );
      return branchingDecisionResults;
    }

    // Sort m_UserActionsFeedbacksInfos by PriorityRank
    branchingDecisionResults.UserActionsFeedbacks.sort((a, b) => {
      return b.PriorityRank - a.PriorityRank;
    });

    // Get the most prioritary user action feedback
    const prioritaryUserActionFeedback =
      this.AvailableUserActionsFeedbacks[branchingDecisionResults.UserActionsFeedbacks[0].ID];

    branchingDecisionResults.PrioritaryUserActionFeedback = {
      ID: prioritaryUserActionFeedback.ID,
      DisplayedName: prioritaryUserActionFeedback.DisplayedName,
      MissedOpportunity: prioritaryUserActionFeedback.MissedOpportunity,
      Tags: prioritaryUserActionFeedback.Tags,
      PriorityRank: prioritaryUserActionFeedback.PriorityRank,
      IsToast: prioritaryUserActionFeedback.IsToast
    };
    // Get the final branching decision evaluation from the most prioritary user action feedback
    // Good if GOOD_ACTION tag and no missed opportunity

    if (
      branchingDecisionResults.PrioritaryUserActionFeedback.Tags.includes(
        USER_ACTION_TAGS.GOOD_ACTION
      ) &&
      !branchingDecisionResults.PrioritaryUserActionFeedback.MissedOpportunity
    ) {
      branchingDecisionResults.Evaluation = FEEDBACK_EVALUATIONS.GOOD;
    }
    // Bad if BAD_ACTION tag or missed opportunity
    else if (
      branchingDecisionResults.PrioritaryUserActionFeedback.Tags.includes(
        USER_ACTION_TAGS.BAD_ACTION
      ) ||
      branchingDecisionResults.PrioritaryUserActionFeedback.MissedOpportunity
    ) {
      branchingDecisionResults.Evaluation = FEEDBACK_EVALUATIONS.BAD;
    }
    // Fail if LIMIT_CASE tag
    else if (
      branchingDecisionResults.PrioritaryUserActionFeedback.Tags.includes(
        USER_ACTION_TAGS.LIMIT_CASE
      )
    ) {
      branchingDecisionResults.Evaluation = FEEDBACK_EVALUATIONS.FAIL;
    }
    // Neutral otherwise
    else {
      branchingDecisionResults.Evaluation = 'NEUTRAL';
    }

    // Create branchingDecisionResults.UserActionsFeedbacks by filtering m_UserActionsFeedbacksInfos
    branchingDecisionResults.FinalUserActionsFeedbacks = [];
    // If GOOD, only keep consecutive UAFs with GOOD_ACTION tag (from the first one with the highest PriorityRank, to the first one without the GOOD_ACTION tag)
    if (branchingDecisionResults.Evaluation === FEEDBACK_EVALUATIONS.GOOD) {
      for (let userActionFeedback of branchingDecisionResults.UserActionsFeedbacks) {
        if (userActionFeedback.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION)) {
          branchingDecisionResults.FinalUserActionsFeedbacks.push(userActionFeedback);
        } else {
          break;
        }
      }
    }
    // If not GOOD, only keep consecutive UAFs without GOOD_ACTION tag (from the first one with the highest PriorityRank, to the first one with the GOOD_ACTION tag)
    else {
      for (let userActionFeedback of branchingDecisionResults.UserActionsFeedbacks) {
        if (!userActionFeedback.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION)) {
          branchingDecisionResults.FinalUserActionsFeedbacks.push(userActionFeedback);
        } else {
          break;
        }
      }
    }

    return branchingDecisionResults;
  }

  LogComposedUserActionsAnalysisToDynamoDB(iDetectedUserActions, iNewUserActionsIDs) {
    window.sdk
      .AnalysisTask()
      .createOne(
        this.DatabaseID, // Parent Branching Decision Node
        this.ID.toString(), // Node ID
        'ComposedUserActionsSolver', // analyzer Engine
        'NA', // Analyzer Version
        'raw', // Analysis Status
        JSON.stringify({
          Rules: this.m_UserActionEvaluator ? this.m_UserActionEvaluator.rules : {},
          DefaultUserAction: this.DefaultUserActionID,
          DetectedUserActions: iDetectedUserActions
        }), // Analysis Input
        this.m_BranchDetectionStartTime, // Start Time
        this.m_BranchDetectionDuration.toString(), // Analysis duration (milliseconds)
        'NA', // Possible choices
        JSON.stringify({
          NewUserActionsIDs: iNewUserActionsIDs,
          TotalUserActionsIDs: [...iDetectedUserActions, ...iNewUserActionsIDs]
        }), // Analysis Result
        this.Graph.ExerciseID.toString() // Exercise ID
      )
      .then((res) => {
        // Update old analysis tasks status to "ignored" if existing
        if (this.previousComposedUAAnalysisTaskID) {
          window.sdk
            .AnalysisTask()
            .updateOne(this.DatabaseID, this.previousComposedUAAnalysisTaskID, {
              AnalysisStatus: 'ignored',
              AnalyzerEngine: 'ComposedUserActionsSolver'
            });
        }
        this.previousComposedUAAnalysisTaskID = res.ID;
      });
  }

  UseAnalysisResultModeSpecific() {
    //////////////////////////
    // History push
    const currentActName = this.Graph.GetCurrentActName();
    const currentSceneName = this.Graph.GetCurrentSceneName();
    const currentSceneNodeID = this.Graph.GetCurrentSceneNodeID();

    // Push full branching decision results to history
    this.Graph.History.AddBranchingDecisionResults(
      this.ID,
      this.m_BranchingDecisionResults,
      currentSceneName,
      currentActName,
      this.DatabaseID
    );

    // Push all detected actions to history
    for (let userAction of this.m_BranchingDecisionResults.UserActions) {
      this.Graph.History.AddUserAction(this.ID, userAction.ID, currentActName, this.DatabaseID);
    }

    // Push user actions feedbacks to history (only UAFs after filtering)
    for (let userActionFeedback of this.m_BranchingDecisionResults.FinalUserActionsFeedbacks) {
      this.Graph.History.AddUserActionFeedback(
        this.ID,
        userActionFeedback.ID,
        userActionFeedback.MissedOpportunity,
        currentActName,
        this.DatabaseID,
        currentSceneNodeID
      );
    }

    //////////////////////////
    // Increment counters
    // Increment user actions counters
    this.Graph.Counters.IncrementUserActionsCounters(
      [...this.m_BranchingDecisionResults.UserActions.map((item) => item.ID)],
      this
    );

    // Increment user actions feedback counters (only UAFs after filtering)
    if (this.m_BranchingDecisionResults.FinalUserActionsFeedbacks.length > 0) {
      this.Graph.Counters.IncrementUserActionFeedbacksCounters(
        [...this.m_BranchingDecisionResults.FinalUserActionsFeedbacks.map((item) => item.ID)],
        this
      );
    }

    // Increment evaluation counters
    switch (this.m_BranchingDecisionResults.Evaluation) {
      case FEEDBACK_EVALUATIONS.GOOD:
        this.Graph.Counters.IncrementGoodActionsCounters(this.BranchingDecisionName);
        break;
      case FEEDBACK_EVALUATIONS.BAD:
        this.Graph.Counters.IncrementBadActionsCounters(this.BranchingDecisionName);
        break;
      case FEEDBACK_EVALUATIONS.FAIL:
        this.Graph.Counters.IncrementFailActionsCounters(this.BranchingDecisionName);
        break;
      default:
        this.Graph.Counters.IncrementNeutralActionsCounters(this.BranchingDecisionName);
    }

    //////////////////////////
    // Prepare Speech HighLight
    this.m_SpeechPartsToHighlightData = {
      detectedUserActionsData: {},
      composedUserActionsData: {},
      userActionFeedbacksData: {}
    };

    // Save detected user actions data for speech highlight
    this.m_SpeechPartsToHighlightData.detectedUserActionsData = {
      userActionsIDs: this.m_BranchingDecisionResults.DetectedUserActions.map((item) => item.ID),
      conversation: this.m_PreviousConversation
    };

    // Save composed user actions data for speech highlight
    let composedUserActionsData = {};
    for (let composedUserAction of this.m_BranchingDecisionResults.ComposedUserActions) {
      composedUserActionsData[composedUserAction.ID] = {
        contributingActionIDs: composedUserAction.ContributingActionIDs
      };
    }
    this.m_SpeechPartsToHighlightData.composedUserActionsData = composedUserActionsData;

    // Save user action feedback data for speech highlight (only UAFs after filtering)
    for (let userActionFeedback of this.m_BranchingDecisionResults.FinalUserActionsFeedbacks) {
      this.m_SpeechPartsToHighlightData.userActionFeedbacksData[userActionFeedback.ID] = {
        SourceUserActionsIDs: userActionFeedback.SourceUserActionsIDs
      };
    }

    // Generate speech parts highlights
    SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights(
      this.Graph,
      this.Graph.History,
      this.Graph.ExerciseID,
      this.DatabaseID,
      {
        ...this.m_SpeechPartsToHighlightData // Copy to avoid synching with the original data
      }
    );

    //////////////////////////
    // Toast feedbacks
    // Prepare the prioritary UAF toast
    for (let userActionFeedback of this.m_BranchingDecisionResults.FinalUserActionsFeedbacks) {
      if (userActionFeedback.IsToast === true) {
        this.PushUserActionToast(userActionFeedback.ID, userActionFeedback.MissedOpportunity);
      }
    }

    // Make the user actions toasts pop in the UI
    this.MakeUserActionToastsPop();
  }

  MakeUserActionToastsPop() {
    const toastUserActions = this.FilterToastUserActions();

    // Add popped user actions to history
    this.Graph.History.AddPoppedUserActions(
      this.ID,
      toastUserActions.map((uaf) => ({
        UserActionFeedbackID: uaf.ID,
        IsMissedOpportunity: uaf.IsMissedOpportunity,
        Evaluation: this.ComputeToastEvaluation(uaf)
      })),
      this.Graph.GetCurrentActName(),
      this.Graph.LastBranchingDecisionNode.DatabaseID
    );

    // Make user actions toasts pop in the UI
    this.Graph.Notifier.notify({
      type: GraphEventTypes.UserActionToasts,
      content: {
        userActionToasts: toastUserActions.map((uaf) => ({
          id: uaf.ID,
          evaluation: this.ComputeToastEvaluation(uaf),
          body: uaf.IsMissedOpportunity ? uaf.MissedOpportunityDisplayName : uaf.DisplayedName
        }))
      }
    });

    // Reset the list of user actions to pop
    this.ToastUserActionsToPop = [];
  }

  ComputeToastEvaluation(iUserActionFeedback) {
    // Check for limit case first
    if (iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    // Check for missed opportunities or bad actions
    if (
      iUserActionFeedback.IsMissedOpportunity ||
      iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.BAD_ACTION)
    ) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    // Default case - must be a good action
    return FEEDBACK_EVALUATIONS.GOOD;
  }

  async LogAnalysisResultToDynamoDB(iResult) {
    let status = 'raw';

    // Handle errors
    if (!iResult.answer || iResult.status !== 'success') {
      status = 'failed';
    }

    // Log branching decision analysis to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'BranchSolver', // analyzer Engine
      'NA', // Analyzer Version
      status, // Analysis Status
      JSON.stringify(
        {
          UserActions: iResult.answer,
          DetectionParametersUsed: iResult.request
        },
        null,
        2
      ), // Analysis Input
      this.m_BranchDetectionStartTime, // Start Time
      this.m_BranchDetectionDuration.toString(), // Analysis duration (milliseconds)
      iResult.possibleBranches, // Possible choices
      JSON.stringify(
        {
          'Final choice': iResult.branch,
          PrioritaryUserAction: iResult.prioritaryUserAction
        },
        null,
        2
      ), // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );
  }

  // Test mode: force user actions and wait for them
  ForceUserActions(iUserActionIDs) {
    log.debug(
      this.GetIdentity() + '.ForceUserActions: Forcing user actions detection of: ',
      iUserActionIDs
    );

    this.m_ForcedUserActionsIDs = iUserActionIDs;
  }

  ResetResults() {
    this.m_BranchingDecisionResults = {};
    this.m_DetectedUserActionsInfos = [];
    this.m_ComposedUserActionsInfos = [];
    this.m_UserActionsFeedbacksInfos = [];
    this.m_TotalUserActionsInfos = [];
    this.m_FinalUserActionsFeedbacksInfos = [];
    this.m_ForcedUserActionsIDs = [];

    this.m_SpeechPartsToHighlightData = {
      detectedUserActionsData: {},
      composedUserActionsData: {},
      userActionFeedbacksData: {}
    };
  }

  Reset() {
    super.Reset();
    this.ResetResults();
  }
}
