import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';

class RegisterPending extends React.Component {
  state = {
    email: '',
    lastname: '',
    firstname: '',
    entity: '',
    failed: false,
    successed: false,
    info: {},
    visible: false,
    valided: {
      number: false,
      special: false,
      length: false,
      min: false,
      maj: false,
      email: true,
      lastname: true,
      firstname: true,
      entity: true
    }
  };

  // Test si email valide
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Envoie le formulaire
  submit = async () => {
    let error = false;
    const { email, lastname, firstname, entity, valided } = this.state;
    if (!email || !valided.email) {
      valided.email = false;
      this.setState({
        valided: valided
      });
      error = true;
    }
    if (!lastname) {
      valided.lastname = false;
      this.setState({
        valided: valided
      });
      error = true;
    }
    if (!firstname) {
      valided.firstname = false;
      this.setState({
        valided: valided
      });
      error = true;
    }
    if (!entity) {
      valided.entity = false;
      this.setState({
        valided: valided
      });
      error = true;
    }
    if (error) {
      return false;
    }

    let res;
    try {
      res = await window.sdk.user().registerPending({
        Email: this.state.email,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        Entity: this.state.entity
      });
    } catch (e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion'
        }
      });
      window.sdk.event().emit('loaderHide');
    }

    if (res && res.state === 'fail') {
      this.setState({
        failed: true,
        info: res.info
      });
    } else {
      this.setState({
        successed: true
      });
    }
  };

  updateEmail = (value) => {
    const { valided } = this.state;
    valided.email = value.length === 0 ? true : this.validateEmail(value);
    this.setState({
      email: value,
      failed: false,
      valided: valided
    });
  };

  updateField = (key, value) => {
    this.setState({ [key]: value });
    this.forceUpdate();
  };

  render() {
    const { valided } = this.state;
    return (
      <>
        {this.state.successed ? (
          <>
            <h2 className="title">Pré inscription enregistré</h2>
            <p className="mt-4">texte descriptif complémentaire</p>
          </>
        ) : (
          <>
            <h2 className="title">Bienvenue, text pré inscription</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <Form.Group className="mt-4" controlId="email">
                <Form.Label className="pl-4 label">Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Votre adresse email"
                  onChange={(e) => this.updateEmail(e.target.value.toLowerCase())}
                  value={this.state.uname}
                  required
                  isInvalid={!valided.email}
                />
              </Form.Group>
              <Form.Group className="mt-4" controlId="firstname">
                <Form.Label className="pl-4 label">First name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  autoComplete="firstname"
                  placeholder="Votre prénom"
                  onChange={(e) => this.updateField('firstname', e.target.value)}
                  value={this.state.firstname}
                  required
                  isInvalid={!valided.firstname}
                />
              </Form.Group>
              <Form.Group className="mt-4" controlId="lastname">
                <Form.Label className="pl-4 label">Last name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  autoComplete="lastname"
                  placeholder="Votre nom de famille"
                  onChange={(e) => this.updateField('lastname', e.target.value)}
                  value={this.state.lastname}
                  required
                  isInvalid={!valided.lastname}
                />
              </Form.Group>
              <Form.Group className="mt-4" controlId="entity">
                <Form.Label className="pl-4 label">Company</Form.Label>
                <Form.Control
                  type="text"
                  name="entity"
                  autoComplete="company"
                  placeholder="Votre entreprise"
                  onChange={(e) => this.updateField('entity', e.target.value)}
                  value={this.state.entity}
                  required
                  isInvalid={!valided.entity}
                />
              </Form.Group>

              {this.state.failed && (
                <Alert className="alert mt-4" variant="danger">
                  {this.state.info.message}
                </Alert>
              )}

              <button
                className="cta cta--accent mt-4"
                disabled={this.props.loading}
                onClick={this.submit}>
                Register
              </button>

              <p className="mt-4">
                <LinkContainer to="/">
                  <a className="link text-xs">Déjà inscrit ?</a>
                </LinkContainer>
              </p>
            </form>
          </>
        )}
      </>
    );
  }
}

export default RegisterPending;
