import React, { useContext } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const NeedInteraction = () => {
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);

  const iHaveInteracted = () => {
    setStep('startWaitingForWebcam');
  };

  return (
    <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'need-interaction'}>
      <button
        id="initialization-continue-button"
        className="cta cta--accent"
        onClick={iHaveInteracted}>
        <Translation keyName="needInteraction.continue">Cliquer ici pour continuer</Translation>
      </button>
    </BottomBar>
  );
};

export default NeedInteraction;
