import React, { useContext, useState } from 'react';
import Utils from '@/AppClasses/Utils/Utils';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const Welcome = () => {
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);
  const [welcomeStepActive, setWelcomeStepActive] = useState(0);

  const step = ['welcome', 'help'];

  const next = async () => {
    let nextStepIndex = welcomeStepActive + 1;
    let page = document.getElementById('primary');
    if (!step[nextStepIndex]) {
      if (page) {
        page.classList.remove('highlight');
      }
      window.sdk.usersActivity().createOne('FTUEOnboardingView', { Date: new Date() });
      setStep('materiel');
    } else setWelcomeStepActive(welcomeStepActive + 1);

    if (step[nextStepIndex] === 'help') {
      let btn = document.getElementById('welcome-button');
      if (btn) btn.classList.add('fadeIn3');
      await Utils.Sleep(500);
      if (page) {
        page.classList.add('highlight');
      }
    }
  };

  const content = () => {
    switch (step[welcomeStepActive]) {
      case 'welcome':
        return (
          <h2 className="mx-auto max-w-row text-center title lg:text-4xl">
            <Translation keyName="welcome.welcome">Bienvenue dans Practicio !</Translation>
          </h2>
        );
      case 'help':
        return (
          <>
            <h2 className="mx-auto max-w-row text-center title">
              <Translation keyName="welcome.question_problem">
                Une question ou un problème au cours de votre expérience Practicio ?
              </Translation>
            </h2>
            <p className="fadeIn500ms mx-auto max-w-row text-center text-base">
              <Translation keyName="welcome.support">
                Contactez-nous à n'importe quel moment en cliquant sur le bouton “Aide” en bas à
                droite de votre écran.
              </Translation>
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {content()}
      <BottomBar
        className="new-step__bottom"
        ExerciseGraph={ExerciseGraph}
        played={false}
        step={'welcome'}>
        <button id="welcome-button" className="cta cta--accent" onClick={next}>
          <Translation keyName="general.next">Suivant</Translation>
        </button>
      </BottomBar>
    </>
  );
};

export default Welcome;
