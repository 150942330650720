import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class UserAction extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  UserActionID = '';
  UserActionTypes = [];

  // Dynamic values
  Phase = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.UserActionID = iProperties.UserActionID;
    this.UserActionTypes = iProperties.UserActionTypes;

    /*log.debug(
      `${this.GetIdentity()} constructor: graph = ${this.Graph.ExerciseName}, UserActionID = ${this.UserActionID}, UserActionTypes = ${this.UserActionTypes}, Phase = ${this.Phase}`
    );*/
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(
      `${this.GetIdentity()}.OnActivated: Activated by '${iActivationLink.Source.Node.GetIdentity()}'.`
    );

    // Get corresponding user action from graph
    let userAction = this.Graph.AvailableUserActions[this.UserActionID];

    if (!userAction) {
      log.debug(
        `${this.GetIdentity()}.OnActivated: User action '${this.UserActionID}' not found in graph.`
      );
      return;
    }

    // Add user action to history
    this.Graph.History.AddUserAction(
      this.ID,
      userAction.ID,
      this.Graph.GetCurrentActName(),
      this.Graph.LastBranchingDecisionNode.DatabaseID
    );

    // TO FINISH FOR SCENARIO 1

    // Get corresponding user action feedback
    let userActionFeedback =
      this.Graph.AvailableUserActionsFeedbacks[userAction.LinkedUserActionFeedback];

    // Make it pop up
    if (userAction.IsToast === true) {
      window.sdk.event().emit('popUserActionsToasts', [userAction]);
    }

    this.SetActive(false);
  }
}
