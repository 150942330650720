import React from 'react';

const ProgressBar = (props: { progress: number }) => {
  return (
    <div className="progress-bar relative flex h-8 w-full items-center justify-center overflow-hidden rounded-2.5xl bg-white">
      <div
        className="progress absolute left-0 h-full"
        style={{ width: `${props.progress}%`, backgroundColor: 'rgb(234 108 93 / 1)' }}></div>
      <p className="z-1 font-semibold text-light-brand">{props.progress}%</p>
    </div>
  );
};

export default ProgressBar;
