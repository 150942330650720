import React, { useContext, useEffect } from 'react';
import log from 'loglevel';
import { isSafari } from 'react-device-detect';
import { Translation } from '@/components/Utilities/Translation';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const MicValidation = () => {
  const { setStep, ExerciseID, jsonGraph, tryStart, setTryStart } = useContext(ExerciseContext);
  let transcriptionSession;

  useEffect(() => {
    let tryMic = tryStart || 0;
    setTryStart(tryMic++);
    micValidation();

    return () => {
      if (transcriptionSession) {
        transcriptionSession.close();
      }
    };
  }, []);

  const micValidation = async () => {
    if (isSafari && !window.sdk.isInIframe()) {
      log.debug('safari in non iframe we do not go to fullscreen');
    } else {
      window.sdk.openFullscreen();
    }
    if (
      ExerciseID === '1' ||
      (jsonGraph.CustomizationValues && jsonGraph.CustomizationValues.skipCamMicSetup) ||
      (window.testMode && window.testMode.noDevicesMode)
    ) {
      goNextStep();
      return;
    }

    await updateDevices();

    try {
      if (!window.testMode.skipDeviceActivation) {
        const videoconfManager = window.sdk.videoconf();
        await videoconfManager.mediaDevices().getUserMedia();

        const speechToTextManager = videoconfManager.getSpeechToTextManager();
        transcriptionSession =
          await speechToTextManager.createMicValidationSession(OnSpeechDetected);
        await transcriptionSession.start();
      }
    } catch (error) {
      log.error('Failed to initialize media devices or speech recognition:', error);
      // Handle the error appropriately, maybe show a message to the user
      return;
    }

    // If test mode asks to skip this step, we send a fake "c'est parti" to continue after 3s
    if (window.testMode.skipSTTSetupTest || window.testMode.noDevicesMode) {
      log.debug('micvalidation skipping');
      OnSpeechDetected("c'est parti");
    }
  };

  const updateDevices = async () => {
    let deviceAudioId = window.localStorage.getItem('device-audioinput');
    if (!deviceAudioId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'audioinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('audioinput')
        );
    }

    let deviceVideoId = window.localStorage.getItem('device-videoinput');
    if (!deviceVideoId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'videoinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('videoinput')
        );
    }
  };

  const OnSpeechDetected = (iResult) => {
    if (!iResult) return;

    const regex = /c'est parti/g;
    const englishRegexp = /let's go/g;
    const tokenFound = iResult.match(regex) || iResult.match(englishRegexp);

    if (tokenFound) {
      if (window.zE) {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', function () {
          window.zE('webWidget', 'hide');
        });
      }
      if (transcriptionSession) {
        transcriptionSession.close();
      }
      goNextStep();
    }
  };

  const goSettings = () => {
    if (transcriptionSession) {
      transcriptionSession.close();
    }

    setStep('settings');
  };

  const goNextStep = () => {
    if (jsonGraph.ExerciseSettings && jsonGraph.ExerciseSettings.HasIntroPanel) {
      setStep('intro');
      return;
    }

    setStep('briefing');
  };

  return (
    <div className="mx-auto max-w-row">
      <h2
        className="mic-validation-instruction mb-0 text-center title lg:text-4xl [&_b]:text-speak"
        id="mic-validation-instruction"
        data-testid="cest-parti">
        <Translation keyName="micValidation.ready">Quand vous êtes prêt.e, dites</Translation>"
        <Translation keyName="micValidation.letsgo">C'est parti !</Translation>"
      </h2>
      {tryStart === 1 ? (
        <p className="fadeIn5 mt-6 text-center text-base">
          <Translation keyName="micValidation.nothing1">Rien ne se passe ?</Translation>
        </p>
      ) : (
        <p className="fadeIn5 mt-4 text-center text-base">
          <Translation keyName="micValidation.nothing2">Toujours rien ?</Translation>
        </p>
      )}
      {tryStart === 1 ? (
        <p className="fadeIn7 mt-6 text-center text-base">
          <Translation keyName="micValidation.reconfigure">
            Reconfigurez votre micro et votre caméra en
          </Translation>{' '}
          <button className="link text-accent hover:text-accent" onClick={goSettings}>
            <Translation keyName="micValidation.click_here">cliquant ici</Translation>
          </button>
        </p>
      ) : (
        <p className="fadeIn7 mt-6 text-center text-base">
          <Translation keyName="micValidation.reconfigure_mic">
            Reconfigurez votre micro en
          </Translation>{' '}
          <button className="link text-accent hover:text-accent" onClick={goSettings}>
            <Translation keyName="micValidation.click_here">cliquant ici</Translation>
          </button>{' '}
          <Translation keyName="micValidation.support">
            ou contactez le support à l'aide du widget en bas à droite
          </Translation>
        </p>
      )}
    </div>
  );
};

export default MicValidation;
