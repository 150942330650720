import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class CounterValueSwitch extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Default = new NodePort('Default', 'output', this);

  // Parameters
  CounterName = '';
  Branches = [];

  // Dynamic values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.CounterName = iProperties.CounterName;

    iProperties.Branches.forEach((branch) => {
      let newBranch = new Branch(branch.ID, branch.Value);
      this.Branches.push(newBranch);

      this[newBranch.GetOutputPortName()] = new NodePort(
        newBranch.GetOutputPortName(),
        'output',
        this
      );
    });
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // Choose the output port and activate it
    this.ChooseOutputPort().Activate();

    this.SetActive(false);
  }

  ChooseOutputPort() {
    // Get the value of the target counter
    const counterValue = this.Graph.Counters.GetValue(this.CounterName);

    // Choose the branch which has the same value as the target counter and return the corresponding output port
    for (let branch of this.Branches) {
      if (branch.Value === counterValue) {
        return this[branch.GetOutputPortName()];
      }
    }

    // If no branch chosen, return the default output port
    return this.Default;
  }
}

class Branch {
  ID = -1;
  Value = 0;

  constructor(iID, iValue) {
    this.ID = iID;
    this.Value = iValue;
  }

  GetOutputPortName() {
    return 'Branch' + this.ID;
  }

  ToString() {
    return `{
  ID: '${this.ID}'
  OutputPort: '${this.GetOutputPortName()}'
  Value: '${this.Value}'
}`;
  }
}
