import React, { useContext, useEffect, useRef, useState } from 'react';
import log from 'loglevel';
import { browserName } from 'react-device-detect';
import GetUserMedia from '@/components/MediaDevices/GetUserMedia';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const Authorization = () => {
  const texts = {
    Edge: [
      'Cliquez sur le cadenas à gauche de la barre d’adresse, en haut de votre écran',
      'Autorisez la caméra et le micro',
      '  Cliquez sur « Vérifier l’autorisation » ci-dessous'
    ],
    Safari: [
      'Allez dans le menu Apple > Réglages Système > Confidentialité et Sécurité',
      ' Autorisez l’accès à Practicio dans « Appareil Photo » et « Microphone »',
      'Cliquez sur « Vérifier l’autorisation » ci-dessous'
    ],
    Firefox: [
      'Cliquez sur les icônes de caméra et de micro à gauche de la barre d’adresse, en haut de votre écran',
      ' Supprimez les blocages temporaires sur la caméra et le micro',
      'Cliquez sur « Vérifier l’autorisation » ci-dessous'
    ]
  };

  const firsStep = () => {
    if (window.sdk.user().refuseAccess) return 3;
    else if (window.sdk.user().firtTimeUserExp()) return 0;
    else return 1;
  };
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);
  const [auhorizationStepActive, setAuhorizationStepActive] = useState(firsStep());
  const [textButton, setTextButton] = useState(
    <Translation keyName="general.next">Suivant</Translation>
  );
  const [retryAfterRefuse, setRetryAfterRefuse] = useState(false);

  const listeners = [];
  const step = ['intro', 'ask', 'waiting', 'tutorial', 'success'];
  const permissionDenied = useRef(false);
  useEffect(() => {
    if (window.localStorage.getItem('webcamPermission') === 'denied') {
      permissionDenied.current = true;
      showTutorial();
      window.localStorage.removeItem('webcamPermission');
    }
    return () => {
      for (const i in listeners) {
        listeners[i]();
      }
    };
  }, []);

  const onButtonToShow = (
    changeBrowser,
    camUsed,
    showPopinInfo,
    showPopinRefuse,
    waitAuthorization
  ) => {
    log.debug(
      'onButtonToShow',
      changeBrowser,
      camUsed,
      showPopinInfo,
      showPopinRefuse,
      waitAuthorization
    );
    /*this.setState({
        getUserMediaState: {
            changeBrowser, camUsed, showPopinInfo, showPopinRefuse, waitAuthorization
        }
    });*/
  };

  const OnStreamReady = () => {
    //if (isStreamReady) return;
    log.debug('Webcam OnStreamReady');
    //setIsStreamReady(true);
    setStep('configuration');
  };

  const showTutorial = (retryAfterRefuse = false) => {
    log.debug('showTutorial', retryAfterRefuse);
    setRetryAfterRefuse(retryAfterRefuse);
    setAuhorizationStepActive(step.indexOf('tutorial'));
    setTextButton(
      <Translation keyName="authorisation.check_button">Vérifier l'autorisation</Translation>
    );
  };

  const OnUpdateStatus = (status) => {
    switch (status) {
      case 'processing':
      case 'failed':
        setTextButton('');
        break;
      default:
        if (!retryAfterRefuse)
          setTextButton(
            <Translation keyName="authorisation.ask_button">Demander l'autorisation</Translation>
          );
    }
  };

  const next = () => {
    let nextStepIndex = auhorizationStepActive + 1;
    if (step[auhorizationStepActive] === 'tutorial') {
      permissionDenied.current = true;
      setAuhorizationStepActive(step.indexOf('ask'));
    } else if (!step[nextStepIndex]) setStep('authorization');
    else setAuhorizationStepActive(auhorizationStepActive + 1);
    if (step[auhorizationStepActive] === 'intro')
      setTextButton(
        <Translation keyName="authorisation.ask_button">Demander l'autorisation</Translation>
      );
    if (step[auhorizationStepActive] === 'tutorial') {
      setTextButton('');
    }
    if (step[auhorizationStepActive] === 'ask') {
      setTextButton(
        <Translation keyName="authorisation.ask_button">Demander l'autorisation</Translation>
      );
      window.sdk.event().emit('getUserMediaAsk');
    }
  };
  const content = () => {
    switch (step[auhorizationStepActive]) {
      case 'intro':
        return (
          <h2 className="mx-auto max-w-half-row text-center title-lg">
            <Translation keyName="authorisation.enjoy">
              Pour profiter de l'expérience Practicio, nous allons devoir autoriser l'application à
              accéder à votre caméra et votre micro.
            </Translation>
          </h2>
        );
      case 'tutorial':
        return (
          <ol className="mx-auto max-w-half-row list-decimal">
            <li>
              {texts[browserName] ? (
                texts[browserName][0]
              ) : (
                <Translation keyName="authorisation.click_slider">
                  Cliquez sur les curseurs à gauche de la barre d'adresse, en haut de votre écran
                </Translation>
              )}
            </li>
            <li className="mt-6">
              {texts[browserName] ? (
                texts[browserName][1]
              ) : (
                <Translation keyName="authorisation.edge2">
                  Autorisez la caméra et le micro
                </Translation>
              )}
            </li>
            <li className="mt-6">
              {texts[browserName] ? (
                texts[browserName][2]
              ) : (
                <Translation keyName="authorisation.check">
                  Cliquez sur « Vérifier l’autorisation » ci-dessous
                </Translation>
              )}
            </li>
          </ol>
        );
      default:
        return (
          <GetUserMedia
            showLoader={() => {}}
            onButtonToShow={onButtonToShow}
            onStreamReady={OnStreamReady}
            showTutorial={showTutorial}
            askOnLoad={permissionDenied.current}
            OnUpdateStatus={OnUpdateStatus}
            noShowAsk={retryAfterRefuse}
          />
        );
    }
  };

  return (
    <>
      {content()}
      <BottomBar
        className="mt-8"
        ExerciseGraph={ExerciseGraph}
        played={false}
        step={'authorization'}>
        {step[auhorizationStepActive] !== 'waiting' && textButton && (
          <button
            id="authorization-button"
            data-testid="authorization-button"
            className="cta cta--accent"
            onClick={next}>
            {textButton}
          </button>
        )}
      </BottomBar>
    </>
  );
};

export default Authorization;
