import React from 'react';
import log from 'loglevel';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';

export function withRouter(Children) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Children {...props} navigate={navigate} match={location.state} />;
  };
}

class PasswordLost extends React.Component {
  state = {
    email: this.props.match ? this.props.match.email : '',
    status: '',
    info: {}
  };

  // On form submit
  submit = async () => {
    let res = await window.sdk.user().forgotPassword({
      email: this.state.email
    });

    log.debug('res', res);

    if (res.state === 'fail') {
      log.debug('res', res);
      this.setState({
        status: 'fail',
        info: res.info
      });
    } else {
      this.setState({
        status: 'success',
        info: res.info
      });
      this.props.navigate('/edit-password', false);
    }
  };

  // Handle email field change
  updateEmail = (value) => {
    this.setState({
      email: value,
      status: ''
    });
  };

  render() {
    return (
      <>
        <h2 className="title">
          <Translation keyName="passwordLost.email">
            Recevez par email un code de renouvellement de mot de passe
          </Translation>{' '}
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          {this.state.status === 'fail' && (
            <Alert className="alert mt-4" variant="danger">
              {this.state.info.message}
            </Alert>
          )}

          {this.state.status === 'success' && (
            <Alert className="alert mt-4" variant="success">
              {this.state.info.message}
            </Alert>
          )}

          <Form.Group controlId="email mt-4">
            <Form.Label className="pl-4 label">
              <Translation keyName="general.email">Email</Translation>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              autoComplete="email"
              placeholder={<Translation keyName="general.email">Email</Translation>}
              onChange={(e) => this.updateEmail(e.target.value.toLowerCase())}
              value={this.state.email}
              required
            />
          </Form.Group>

          <button
            className="cta cta--accent mt-4"
            disabled={this.props.loading}
            onClick={this.submit}
            data-testid="password-lost-submit-btn">
            <Translation keyName="general.send">Envoyer</Translation>
          </button>

          <p className="mt-4">
            <LinkContainer to="/">
              <a className="link text-xs">
                <Translation keyName="general.login">Connexion</Translation>
              </a>
            </LinkContainer>
          </p>
        </form>
      </>
    );
  }
}

export default withRouter(PasswordLost);
