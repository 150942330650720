import React, { useContext, useEffect, useState } from 'react';
import log from 'loglevel';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const DebugPanel = (props) => {
  const { ExerciseGraph, cache } = useContext(ExerciseContext);
  const listeners = [];
  const [debugInfo, setDebugInfo] = useState({});

  //////////////////////////////////////////
  // User infos
  const renderUserInfos = () => {
    return (
      <article id="user-infos" className="mb-4 bg-black/50 p-4 text-white">
        <h3 className="title-sm">User:</h3>
        <p className="text-sm font-semibold">
          {`UserID: ${window.sdk.user().userID}`}
          <br />
          {`Name: ${window.sdk.user().firstName} ${window.sdk.user().lastName}`}
          <br />
          {`Email: ${window.sdk.user().email}`}
          <br />
          {`Entity: ${window.sdk.user().entity}`}
          <br />
          {`Role: ${window.sdk.user().role}`}
          <br />
          {`AvailableExercises: ${
            !window.sdk.user().availableExercises || window.sdk.user().availableExercises === ''
              ? 'all'
              : window.sdk.user().availableExercises
          }`}
        </p>
      </article>
    );
  };

  //////////////////////////////////////////
  // LogLevel
  const renderLoglevelOptions = () => {
    return (
      <div id="log-level-controls" className="mb-4">
        <h2 className="title">LogLevel</h2>

        <select
          name="log-level"
          id="log-level"
          value={log.getLevel()}
          onChange={(e) => log.setLevel(parseInt(e.target.value))}>
          <option value="0">0 - Trace</option>
          <option value="1">1 - Debug</option>
          <option value="2">2- Info</option>
          <option value="3">3 - Warn</option>
          <option value="4">4 - Error</option>
        </select>
      </div>
    );
  };

  //////////////////////////////////////////
  // Auto subtitles
  const [autoSubs, setAutoSubs] = useState(window.testMode.autoSubs);
  const [autoSubsLanguage, setAutoSubsLanguage] = useState(window.testMode.autoSubsLanguage);

  const SetAutoSubs = (iActivated) => {
    window.testMode.autoSubs = iActivated;
  };

  const SetAutoSubsLanguage = (iLanguage) => {
    window.testMode.autoSubsLanguage = iLanguage;
  };

  const handleToggleAutoSubs = () => {
    const newMode = !autoSubs;
    setAutoSubs(newMode);
    SetAutoSubs(newMode);
  };

  const handleAutoSubsLanguageChange = (e) => {
    setAutoSubsLanguage(e.target.value);
    SetAutoSubsLanguage(e.target.value);
  };

  const renderAutoSubsOptions = () => {
    return (
      <div id="auto-subs-controls" className="mb-4">
        <h2 className="title">Auto Subtitles</h2>
        <div className="toggle-auto-subs">
          <input
            type="checkbox"
            id="auto-subs-toggle"
            checked={autoSubs}
            onChange={handleToggleAutoSubs}
          />
          <label htmlFor="auto-subs-toggle">Auto subtitles</label>
        </div>
        {autoSubs && (
          <div className="auto-subs-language">
            <label htmlFor="auto-subs-language">Language:</label>
            <input
              type="text"
              id="auto-subs-language"
              value={autoSubsLanguage}
              onChange={handleAutoSubsLanguageChange}
            />
          </div>
        )}
      </div>
    );
  };

  //////////////////////////////////////////
  // Fake user mode
  const [fakeUserMode, setFakeUserMode] = useState(window.testMode.fakeUserMode);
  const [fakeUserParams, setFakeUserParams] = useState({
    contextualInfo: window.testMode.fakeUserParams.contextualInfo,
    userBehaviour: window.testMode.fakeUserParams.userBehaviour,
    temperature: window.testMode.fakeUserParams.temperature || 0
  });

  // Use a checkbox to toggle the fake user mode
  const handleToggleFakeUserMode = () => {
    const newMode = !fakeUserMode;
    setFakeUserMode(newMode);
    window.testMode.fakeUserMode = newMode;
  };

  // Use a text input to set the fake user parameter "contextualInfo"
  const handleContextChange = (e) => {
    setFakeUserParams((prev) => ({
      ...prev,
      contextualInfo: e.target.value
    }));
  };

  // Use a text input to set the fake user parameter "userBehaviour"
  const handleUserBehaviourChange = (e) => {
    setFakeUserParams((prev) => ({
      ...prev,
      userBehaviour: e.target.value
    }));
  };

  // Use a slider to set the fake user parameter "temperature"
  const handleTemperatureChange = (e) => {
    setFakeUserParams((prev) => ({
      ...prev,
      temperature: parseFloat(e.target.value)
    }));
  };

  // Synchronize window.testMode.fakeUserMode with React state
  useEffect(() => {
    window.testMode.fakeUserMode = fakeUserMode;
  }, [fakeUserMode]);

  // Synchronize window.testMode.fakeUserParams with React state
  useEffect(() => {
    window.testMode.fakeUserParams = fakeUserParams;
  }, [fakeUserParams]);

  // Render the fake user options
  const renderFakeUserOptions = () => {
    return (
      <div id="fake-user-controls" className="mb-4">
        <h2 className="title">Fake User</h2>
        <div className="toggle-fake-user-mode">
          <input
            type="checkbox"
            id="fake-mode-toggle"
            checked={fakeUserMode}
            onChange={handleToggleFakeUserMode}
          />
          <label htmlFor="fake-mode-toggle">Fake user mode</label>
        </div>
        {fakeUserMode && (
          <>
            <div className="fake-user-param mt-2">
              <label htmlFor="context">Context :</label>
              <textarea
                id="context"
                className="w-full"
                value={fakeUserParams.contextualInfo}
                onChange={handleContextChange}
              />
            </div>
            <div className="fake-user-param mt-2">
              <label htmlFor="userBehaviour">User Behaviour: </label>
              <textarea
                id="userBehaviour"
                className="w-full"
                value={fakeUserParams.userBehaviour}
                onChange={handleUserBehaviourChange}
              />
            </div>
            <div className="fake-user-param">
              <label htmlFor="temperature">
                Temperature: {fakeUserParams.temperature.toFixed(2)}
              </label>
              <input
                type="range"
                id="temperature"
                min="0"
                max="1"
                step="0.01"
                value={fakeUserParams.temperature}
                onChange={handleTemperatureChange}
              />
            </div>
            <div className="auto-skip-control">
              <input
                type="checkbox"
                id="auto-skip-toggle"
                checked={autoSkip}
                onChange={handleToggleAutoSkip}
              />
              <label htmlFor="auto-skip-toggle">Auto Skip</label>
            </div>
          </>
        )}
      </div>
    );
  };

  //////////////////////////////////////////
  // Auto skip
  const [autoSkip, setAutoSkip] = useState(false);

  const handleToggleAutoSkip = () => {
    setAutoSkip(!autoSkip);
    window.testMode.autoSkip = !autoSkip;
  };

  //////////////////////////////////////////
  // Force user actions
  const [forceUserActionsMode, setForceUserActionsMode] = useState(
    window.testMode.forceUserActionsMode
  );
  const [availableUserActions, setAvailableUserActions] = useState([]);
  const [selectedUserActions, setSelectedUserActions] = useState([]);

  const handleToggleForceMode = () => {
    const newMode = !forceUserActionsMode;
    setForceUserActionsMode(newMode);
    SetForceUserActionsMode(newMode);
  };

  const handleUserActionChange = (actionId) => {
    setSelectedUserActions((prev) =>
      prev.includes(actionId) ? prev.filter((id) => id !== actionId) : [...prev, actionId]
    );
  };

  const SetForceUserActionsMode = (iActivated) => {
    window.testMode.forceUserActionsMode = iActivated;
  };

  const ForceUserActions = () => {
    // Get the selected user actions from availableUserActions
    const userActions = availableUserActions.filter((action) =>
      selectedUserActions.includes(action.ID)
    );

    // To string of user action number separated by coma
    const userActionIDs = userActions.map((action) => action.ID);

    window.sdk.event().emit('forceUserActions', userActionIDs);
  };

  const renderForceUserActionsOptions = () => {
    return (
      <div id="force-useractions-controls">
        <h2 className="title">Force User Actions</h2>
        <div className="toggle-force-useractions-mode">
          <input
            type="checkbox"
            id="force-mode-toggle"
            checked={forceUserActionsMode}
            onChange={handleToggleForceMode}
          />
          <label htmlFor="force-mode-toggle">Force user actions mode</label>
        </div>
        {forceUserActionsMode && (
          <div className="user-actions-list debug_graph_controls_container">
            {availableUserActions.map((userAction) => (
              <div className="toggle-force-useractions-mode" key={userAction.ID}>
                <input
                  type="checkbox"
                  id={userAction.ID}
                  checked={selectedUserActions.includes(userAction.ID)}
                  onChange={() => handleUserActionChange(userAction.ID)}
                />
                <label htmlFor={userAction.ID}>
                  {userAction.Number + ' - ' + userAction.PromptName}
                </label>
              </div>
            ))}
            {availableUserActions.length > 0 && (
              <button className="cta cta--accent debug-button mt-2" onClick={ForceUserActions}>
                Send Selected Actions
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  // Update available user actions when the branching decision node changes
  useEffect(() => {
    if (ExerciseGraph) {
      let userActions = ExerciseGraph.GetCurrentlyPossibleUserActions();
      if (userActions) {
        userActions = userActions.sort((a, b) => a.Number - b.Number);
        setAvailableUserActions(userActions);
      } else {
        setAvailableUserActions([]);
      }
    } else {
      setAvailableUserActions([]);
    }
  }, [ExerciseGraph.LastBranchingDecisionNode]);

  useEffect(() => {
    listeners.push(
      window.sdk.event().on('addDebugInfo', (data) => {
        if (debugInfo[data.iID] !== data.iValue) {
          let newDebugInfo = debugInfo;
          newDebugInfo[data.iID] = data.iValue;
          setDebugInfo(newDebugInfo);
        }

        // Write state debug values
        if (window.testMode.fillAppStateValues)
          window.testMode.appStateValues[data.iID] = data.iValue;
        log.debug('window.testMode.appStateValues', window.testMode.appStateValues);
      })
    );

    return () => {
      for (const i in listeners) {
        listeners[i]();
      }
    };
  }, []);

  //////////////////////////////////////////
  // Skip exercise step
  const skipExerciseStep = () => {
    window.sdk.event().emit('skipExerciseStep');
  };

  //////////////////////////////////////////
  // Stop exercise
  const stopExercise = () => {
    window.sdk.event().emit('stopExercise');
  };

  //////////////////////////////////////////
  // Render
  return (
    <React.Fragment>
      {ExerciseGraph && (
        <div className={'debug debug_graph_controls'}>
          {ExerciseGraph.LastBranchingDecisionNode && (
            <div>
              <p>
                {'Current Branching Decision: ' +
                  ExerciseGraph.LastBranchingDecisionNode.BranchingDecisionName +
                  ' (' +
                  ExerciseGraph.LastBranchingDecisionNode.ID +
                  ')'}
              </p>
            </div>
          )}
          {renderForceUserActionsOptions()}
          <div className="-mx-2 -mb-2 mt-2 flex flex-wrap">
            <button
              id="debug-skip-button"
              className="cta cta--white debug-button m-2"
              onClick={skipExerciseStep}>
              Skip exercise step
            </button>
            <button
              id="debug-stop-button"
              className="cta cta--white debug-button m-2"
              onClick={stopExercise}>
              Stop exercise
            </button>
          </div>
          <br />
          <br />
          {renderAutoSubsOptions()}
          {renderFakeUserOptions()}
          <br />
          {renderUserInfos()}
          <h2 className="title">Active Graph Nodes</h2>
          <p dangerouslySetInnerHTML={{ __html: ExerciseGraph.GetActiveNodesText() }} />
          {renderLoglevelOptions()}
        </div>
      )}
      {cache && (
        <div className={'debug debug_cache_progression'}>
          <p>Current file: {cache.currentFile}</p>
          <p>Current file progression: {cache.currentFileProgression} %</p>
          <p>Total progression: {cache.totalProgression} %</p>
          <p>Average BW: {props.averageBW} Mb/s</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default DebugPanel;
