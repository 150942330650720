import Card from '@UIKit/Card/Card';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const FirstRun = () => {
  const { setStep, ExerciseGraph, jsonGraph } = useContext(ExerciseContext);
  const navigate = useNavigate();
  const exit = () => {
    window.sdk.event().emit('quitExercise');
    navigate('/');
  };

  const startScenario = () => {
    window.sdk.event().emit('startExercise');
    setStep('scenario');
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const timestamp = new Date().getTime();

  return (
    <>
      <div className="mx-auto w-5/6 md:flex md:space-x-6">
        <Card className="md:flex-1" areCustomStyles={false} dataTestid="exercise-briefing-context">
          <div
            className="mt-2 title"
            dangerouslySetInnerHTML={{ __html: jsonGraph.IntroPanel.ContextLabel }}
          />
          <div dangerouslySetInnerHTML={{ __html: jsonGraph.IntroPanel.Context }} />
        </Card>
        <Card
          className="max-md:mt-6 md:flex-1"
          dataTestid="exercise-briefing-objectives"
          areCustomStyles={false}>
          <h2
            className="mt-2 title"
            dangerouslySetInnerHTML={{ __html: jsonGraph.IntroPanel.ObjectivesLabel }}
          />

          <div dangerouslySetInnerHTML={{ __html: jsonGraph.IntroPanel.Objectives }} />

          {isValidURL(ExerciseGraph.IntroPanel.IntroMediaUrl) && (
            <img
              src={`${ExerciseGraph.IntroPanel.IntroMediaUrl}?nocache=${timestamp}`}
              alt="introduction image"
            />
          )}
        </Card>
      </div>
      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'intro-exercise'}>
        <button id="briefing-next-button" className="cta cta--white" onClick={exit}>
          <Translation keyName="general.quit">Quitter</Translation>
        </button>
        <button
          id="briefing-next-button"
          data-testid="start-exercise-button"
          className="cta cta--accent"
          onClick={startScenario}>
          <Translation keyName="general.start_exercise">Commencer l'exercice</Translation>
        </button>
      </BottomBar>
    </>
  );
};
export default FirstRun;
