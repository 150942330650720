import { useTranslate } from '@tolgee/react';
import React from 'react';
import defs from '@/assets/new-ui/defs.svg';

const VisioExerciceBottomBar = ({
  children,
  speechFeedback,
  pauseExercise,
  step,
  pauseButtonDisabled,
  hide,
  hideCam,
  className = '',
  ...props
}) => {
  const { t } = useTranslate();

  return (
    <div className={`mt-8 ${className}`} {...props}>
      <div className="flex min-h-[calc(theme(spacing.16)+2px)] items-center justify-center space-x-6">
        {children}
        {step === 'scenario' && (
          <>
            <button
              id="pause-button"
              className="flex size-10 shrink-0 items-center justify-center rounded-full bg-brand transition-opacity disabled:opacity-50"
              title={t('bottomBar.pause', 'Mettre en pause')}
              disabled={pauseButtonDisabled}
              onClick={pauseExercise}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className="fill-off-white"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false">
                <use href={`${defs}#pause`} />
              </svg>
            </button>
            <button
              id="hide-camera-button"
              className={`flex size-10 shrink-0 items-center justify-center rounded-full transition-opacity disabled:opacity-50 ${
                hide ? 'bg-fail' : 'bg-brand'
              }`}
              title={t('bottomBar.cam_off', 'Masquer la camera')}
              onClick={hideCam}>
              {hide ? (
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  className="fill-off-white"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false">
                  <use href={`${defs}#webcam-off`} />
                </svg>
              ) : (
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  className="fill-off-white"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false">
                  <use href={`${defs}#webcam`} />
                </svg>
              )}
            </button>
          </>
        )}
      </div>
      {speechFeedback && (
        <div className="debug">
          <p className="mt-4 text-center text-sm text-white text-shadow-subtitles">
            {speechFeedback}
          </p>
        </div>
      )}
    </div>
  );
};

export default VisioExerciceBottomBar;
