import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { ExerciseContext } from '@/contexts/ExerciseContext';
import log from 'loglevel';
import { isSafari } from 'react-device-detect';
import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import defs from '@/assets/new-ui/defs.svg';
import TopBar from '@/components/TopBar/TopBar';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { useRetryRunInfo } from '@/hooks/useRetryRunInfo';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import Modal from '@UIKit/Modal/Modal';
import SmCover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm.jpg';
import SmCover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm@2x.jpg';
import Cover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates.jpg';
import Cover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates@2x.jpg';

const ExerciseBriefingPage = () => {
  const {
    ExerciseID,
    setExerciseID,
    jsonGraph,
    ExerciseGraph,
    cache,
    setCache,
    setRerunNodeID,
    setExerciseSessionID,
    ExerciseSessionHistory,
    setStep,
    transcriptionSession,
    setTranscriptionSession,
    resetExerciseContext
  }: any = useContext(ExerciseContext);
  const navigate = useNavigate();
  const { id, RerunNodeID, ExerciseSessionID } = useParams();
  const [context, setContext] = useState('');
  const [objectives, setObjectives] = useState('');
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [isUADetailsTextModalOpened, setIsUADetailsTextModalOpened]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [detailsTextModalContent, setDetailsTextModalContent]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');
  const [detailsTextModalTitle, setDetailsTextModalTitle]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');

  useEffect(() => {
    if (isSafari && !window.sdk.isInIframe()) {
      log.debug('safari in non iframe we do not go to fullscreen');
    } else {
      window.sdk.openFullscreen();
    }

    if (id && id !== ExerciseID) {
      setExerciseID(id);
      window.sdk.setRedirectUrl(`/exercise/${id}`);
    }

    if (RerunNodeID) {
      setRerunNodeID(RerunNodeID);
    }

    if (ExerciseSessionID) {
      setExerciseSessionID(ExerciseSessionID);
    }
  }, []);

  useEffect(() => {
    const preloadVideo = async () => {
      await window.sdk
        .exercise()
        .preloadExerciseAssets(ExerciseGraph, (progressionInPercent: string) => {
          setCache({
            totalProgression: parseInt(progressionInPercent, 10)
          });
        });
    };

    if (ExerciseGraph) {
      preloadVideo();
    }
  }, [jsonGraph, ExerciseGraph]);

  useEffect(() => {
    // Exit early if exercise graph is not available
    if (!ExerciseGraph) {
      return;
    }

    // Handle first run of the exercise - no rerun or session info
    const isFirstRun = (!RerunNodeID && !ExerciseSessionID) || !ExerciseSessionHistory;
    if (isFirstRun) {
      setObjectives(jsonGraph.IntroPanel.Objectives);
      setContext(jsonGraph.IntroPanel.Context);
      return;
    }

    // Get retry run information if available
    const retryRunInfo = useRetryRunInfo({
      exerciseSessionHistory: ExerciseSessionHistory,
      exerciseGraph: ExerciseGraph,
      rerunNodeID: RerunNodeID ?? ''
    });

    // Update state with retry run information
    if (retryRunInfo) {
      const { rerunSceneNode, recommendedUserActionsFeedbacks } = retryRunInfo;
      setObjectives(rerunSceneNode.Objectives || jsonGraph.IntroPanel.Objectives);
      setContext(rerunSceneNode.Context || jsonGraph.IntroPanel.Context);
      setRecommendations(recommendedUserActionsFeedbacks);
    }
  }, [ExerciseGraph, jsonGraph, RerunNodeID, ExerciseSessionID, ExerciseSessionHistory]);

  useEffect(() => {
    if (cache.totalProgression === 100) {
      micValidation();
    }
  }, [cache.totalProgression]);

  const toggleModal = (title: string, content: string) => {
    setDetailsTextModalContent(content);
    setDetailsTextModalTitle(title);
    const newState = !isUADetailsTextModalOpened;
    setIsUADetailsTextModalOpened(newState);
  };

  const micValidation = async () => {
    if (
      (jsonGraph.CustomizationValues && jsonGraph.CustomizationValues.skipCamMicSetup) ||
      (window.testMode && window.testMode.noDevicesMode)
    ) {
      goToVisio();
      return;
    }

    if (window.testMode.skipSTTSetupTest || window.testMode.noDevicesMode) {
      log.debug('micvalidation skipping');
      OnSpeechDetected("c'est parti", null);
    }

    await updateDevices();

    if (window.testMode.skipDeviceActivation) {
      return;
    }

    try {
      const videoconfManager = window.sdk.videoconf();
      await videoconfManager.mediaDevices().getUserMedia();

      const speechToTextManager = videoconfManager.getSpeechToTextManager();
      const localTranscriptionSession = await speechToTextManager.createMicValidationSession(
        (iResult: string) => {
          OnSpeechDetected(iResult, localTranscriptionSession);
        }
      );
      setTranscriptionSession(localTranscriptionSession);
      await localTranscriptionSession.start();
    } catch (error) {
      log.error('Failed to initialize media devices or speech recognition:', error);
      // Handle the error appropriately, maybe show a message to the user
      return;
    }
  };

  const updateDevices = async () => {
    let deviceAudioId = window.localStorage.getItem('device-audioinput');
    if (!deviceAudioId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'audioinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('audioinput')
        );
    }

    let deviceVideoId = window.localStorage.getItem('device-videoinput');
    if (!deviceVideoId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'videoinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('videoinput')
        );
    }
  };

  const OnSpeechDetected = (iResult: string, transcriptionSession: any) => {
    if (!iResult) return;

    const regex = /c'est parti/g;
    const englishRegexp = /let's go/g;
    const tokenFound = iResult.match(regex) || iResult.match(englishRegexp);

    if (tokenFound) {
      if (window.zE) {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', function () {
          window.zE('webWidget', 'hide');
        });
      }

      if (transcriptionSession) {
        transcriptionSession.close();
      }

      goToVisio();
    }
  };

  const goToVisio = () => {
    if (transcriptionSession) {
      transcriptionSession.close();
    }
    navigate(`/exercise/${id}/visio`, { replace: false });
  };

  const goToSettings = () => {
    if (transcriptionSession) {
      transcriptionSession.close();
    }

    resetExerciseContext();
    setStep('settings');

    navigate(`/exercise/${id}/`, { replace: false });
  };

  const showZendesk = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr' as any as Location;
    }
  };

  return (
    <>
      <TopBar className="sticky left-0 top-0 z-50 w-full" />
      <div className="relative z-1 mt-8 lg:fixed lg:left-0 lg:top-12 lg:mt-0 lg:flex lg:h-[calc(100vh-theme(spacing.12))] lg:w-1/4 lg:flex-col">
        <Card
          theme="BRAND"
          className="mx-4 rounded-b-none sm:mx-6 lg:mx-0 lg:flex lg:flex-1 lg:flex-col lg:rounded-l-none">
          {cache && cache.totalProgression < 100 ? (
            <div
              className="align-center flex size-full flex-col justify-center"
              data-testid="briefing-videos-loading-text">
              <h1 className="mb-6 text-center title-sm">
                <Translation keyName="briefing.loadingText">
                  Chargement de l'expérience"
                </Translation>
              </h1>
              <ProgressBar progress={cache.totalProgression} />
            </div>
          ) : (
            <div className="align-center flex size-full flex-col justify-center">
              <svg
                width="32"
                height="54"
                viewBox="0 0 32 54"
                className="mx-auto fill-current"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false">
                <use href={`${defs}#micro`} />
              </svg>
              <p
                className="mt-6 text-center title"
                data-testid="briefing-mic-validation-ready-text">
                <Translation keyName="micValidation.readConfirmation">
                  Vous avez pris connaissance des données de l'exercice ?
                </Translation>
              </p>
              <p className="mt-6 text-center title-sm">
                <Translation keyName="micValidation.ready">
                  Quand vous êtes prêt.e, dites
                </Translation>
                "
                <span className="text-accent">
                  <Translation keyName="micValidation.letsgo">C'est parti !</Translation>
                </span>
                "
              </p>
            </div>
          )}
        </Card>
        <Card
          theme="LIGHT_BRAND"
          className="mx-4 rounded-t-none text-white sm:mx-6 lg:mx-0 lg:rounded-l-none [&]:p-0">
          <Accordion id="help">
            <Accordion.CTALabel className="py-6 pl-6 pr-[calc(18px+theme(spacing.10))] after:right-6 lg:pl-12 lg:pr-[calc(18px+theme(spacing.16))] lg:after:right-12 [&_+_[role='region']]:px-6 lg:[&_+_[role='region']]:px-12">
              <span className="mr-2 inline-flex size-5 items-center justify-center rounded-full bg-current">
                <svg
                  width="4"
                  height="10"
                  viewBox="0 0 4 10"
                  className="fill-brand"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false">
                  <use href={`${defs}#info`} />
                </svg>
              </span>

              <span className="text-sm font-semibold">
                <Translation keyName="briefing.help">Besoin d'aide ?</Translation>
              </span>
            </Accordion.CTALabel>

            <ol className="-mr-6 list-decimal text-sm">
              <li>
                <span className="flex items-center justify-between">
                  <p>
                    <Translation keyName="briefing.micSetup">
                      Reconfigurez votre micro dans les paramètres.
                    </Translation>
                  </p>
                  <button
                    className="cta cta--without-hover-effect ml-auto flex items-center"
                    onClick={goToSettings}>
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      className="mr-2 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false">
                      <use href={`${defs}#settings`} />
                    </svg>
                    <Translation keyName="briefing.settings">Paramètres</Translation>
                  </button>
                </span>
              </li>
              <li>
                <span className="flex items-center justify-between">
                  <Translation keyName="briefing.contactSupport">
                    Contactez l'assistance
                  </Translation>

                  <button
                    className="cta cta--without-hover-effect ml-auto flex items-center"
                    onClick={showZendesk}>
                    <span className="mr-2 flex size-5 items-center justify-center rounded-full bg-current">
                      <svg
                        width="4"
                        height="10"
                        viewBox="0 0 4 10"
                        className="fill-brand"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false">
                        <use href={`${defs}#info`} />
                      </svg>
                    </span>
                    Aide
                  </button>
                </span>
              </li>
            </ol>
          </Accordion>
        </Card>
      </div>

      {context && objectives && (
        <section className="px-4 py-6 bg-blurred-sophie max-lg:pb-8 sm:px-6 lg:relative lg:ml-auto lg:flex lg:min-h-[calc(100vh-theme(spacing.12))] lg:w-3/4 lg:items-center">
          <div className="lg:mx-auto lg:w-full lg:max-w-[70%]">
            <Card
              className="lg:p-11"
              areCustomStyles={false}
              dataTestid="exercise-briefing-context">
              <Card.Border>
                <Card.Title
                  label={
                    <Translation keyName="briefing.contextText">Le contexte en bref</Translation>
                  }></Card.Title>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(context) }} />
              </Card.Border>
            </Card>

            <Card
              className="mt-6 lg:p-11"
              areCustomStyles={false}
              dataTestid="exercise-briefing-objectives">
              <Card.Border color="accent">
                <Card.Title
                  color="accent"
                  label={
                    <Translation keyName="briefing.objectiveText">
                      Votre rôle dans cet exercice
                    </Translation>
                  }>
                  <h2
                    className="mt-2 title"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(jsonGraph.IntroPanel?.ObjectivesLabel)
                    }}
                  />
                </Card.Title>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(objectives) }} />
              </Card.Border>
            </Card>

            {recommendations.length > 0 && (
              <Card
                className="mt-6 lg:p-11"
                areCustomStyles={false}
                dataTestid="exercise-briefing-recommendation">
                <Card.Border color="advice">
                  <Card.Title
                    color="advice"
                    label={
                      <Translation keyName="briefing.recommendationText">
                        Pour votre prochaine interaction, nous vous suggérons
                      </Translation>
                    }>
                    {recommendations[0].DisplayedName}
                  </Card.Title>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div
                        className="flex-1"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(recommendations[0].Description)
                        }}
                      />

                      <button
                        className="cta cta--accent ml-8 whitespace-nowrap"
                        onClick={() =>
                          toggleModal(
                            recommendations[0].DisplayedName,
                            recommendations[0].DetailsText
                          )
                        }>
                        En savoir plus
                      </button>
                    </div>
                  </div>
                </Card.Border>
              </Card>
            )}
          </div>

          {isUADetailsTextModalOpened && (
            <Modal onClose={() => toggleModal('', '')}>
              <Modal.Header
                cover={{
                  alt: '',
                  smImage: {
                    src: SmCover,
                    height: 250,
                    width: 640
                  },
                  smImage2x: {
                    src: SmCover2x,
                    height: 500,
                    width: 1280
                  },
                  image: {
                    src: Cover,
                    height: 250,
                    width: 1000
                  },
                  image2x: {
                    src: Cover2x,
                    height: 500,
                    width: 2000
                  }
                }}>
                <Modal.Title label={<>Tout savoir sur&hellip;</>}>
                  {detailsTextModalTitle}
                </Modal.Title>
              </Modal.Header>
              <Modal.Content>
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(detailsTextModalContent) }}></div>
              </Modal.Content>
            </Modal>
          )}
        </section>
      )}
    </>
  );
};

export default ExerciseBriefingPage;
