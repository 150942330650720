import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class GoToButton extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  ButtonName = '';

  // Internal values
  Button;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.ButtonName = iProperties.ButtonName;

    log.debug(
      `${this.GetIdentity()} constructor: graph = ${this.Graph.ExerciseName}, id = ${this.ID}, ButtonName = ${this.ButtonName}`
    );
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // UI button setup
    window.sdk.event().emit('registerGoToButtonCallbacks', {
      iGoToButtonName: this.ButtonName,
      iRegisterGoToButtonCallback: (value) => this.RegisterButton(value),
      iOnClickedCallback: () => this.OnClicked()
    });

    window.sdk.event().emit('showGoToButton', true);
  }

  RegisterButton(iButton) {
    log.debug(this.GetIdentity() + ' RegisterButton: button = ' + JSON.stringify(iButton));
    this.Button = iButton;
  }

  DestroyButton() {
    window.sdk.event().emit('showGoToButton', false);
  }

  OnClicked() {
    // Hide button
    this.DestroyButton();

    this.Graph.StopAllActiveNodes();

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");
    this.Output.Activate();
  }

  OnDeactivated() {
    super.OnDeactivated();

    // Hide button
    this.DestroyButton();
  }

  PrintParameters() {}
}
