export const CounterTypes = {
  UserAction: 'UA',
  UserActionFeedback: 'UAF',
  GoodActions: 'GoodActions',
  BadActions: 'BadActions',
  FailActions: 'FailActions',
  NeutralActions: 'NeutralActions'
} as const;

export const CounterScopes = {
  Global: 'Global',
  Local: 'Local'
} as const;
