import React from 'react';
import '@/styles/components/AuthLayout.scss';
import Container from 'react-bootstrap/Container';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import video1 from '@/assets/videos/login-video1.mp4';
import video2 from '@/assets/videos/login-video2.mp4';
import video3 from '@/assets/videos/login-video3.mp4';
import DebugFtueUserProfile from '@/components/DebugFtueUserProfile/DebugFtueUserProfile';
import Loader from '@/components/Utilities/Loader';
import { Translation } from '@/components/Utilities/Translation';
import AuthRouting from '@/pages/Auth/AuthRouting';

class AuthLayout extends React.Component {
  state = {
    loading: false
  };

  listeners = [];

  componentDidMount() {
    this.listeners.push(
      window.sdk.event().on('fetchStarted', () => {
        this.setState({
          loading: true
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('fetchFinished', () => {
        this.setState({
          loading: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('loaderHide', () => {
        this.setState({
          loading: false
        });
      })
    );
  }

  // Supprime les events
  componentWillUnmount() {
    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  showZendesk = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr';
    }
  };

  render() {
    return (
      <div className="login-layout legacy">
        <div className={'debug debug_graph_controls'}>
          <DebugFtueUserProfile />
        </div>
        <Loader display={this.state.loading} />
        <div className="login__main">
          <Container>
            <Practicio className="mb-6 h-auto w-52 max-w-full fill-accent" />
            <AuthRouting />
            <p className="mt-16 text-xs">
              <button className="link" onClick={this.showZendesk}>
                <Translation keyName="general.support">Contacter le support</Translation>
              </button>{' '}
              -{' '}
              <a href={window.policyUri} className="link" target="_blank" rel="noreferrer">
                <Translation keyName="general.privacy">Politique de confidentialité</Translation>
              </a>{' '}
              <span className="text-brand/50">- Practicio {window.infoVersion.version}</span>
            </p>
          </Container>
        </div>
        <div className="w-full overflow-hidden bg-brand">
          <div className="login__video login__video1">
            <video
              playsInline
              loop
              muted
              autoPlay
              preload="auto"
              id="LoginVideo1"
              className="login__video_player">
              <source src={video1} type="video/mp4" />
            </video>
            <div className="absolute left-[-1rem] top-1/2 -translate-y-1/2 text-8xl font-light uppercase text-off-white">
              PRACTICE
            </div>
          </div>
          <div className="login__video login__video2">
            <video
              playsInline
              loop
              muted
              autoPlay
              preload="auto"
              id="LoginVideo2"
              className="login__video_player">
              <source src={video2} type="video/mp4" />
            </video>
            <div className="absolute right-0 top-1/2 -translate-y-1/2 text-8xl font-light uppercase text-off-white">
              MAKES
            </div>
          </div>
          <div className="login__video login__video3">
            <video
              playsInline
              loop
              muted
              autoPlay
              preload="auto"
              id="LoginVideo3"
              className="login__video_player">
              <source src={video3} type="video/mp4" />
            </video>
            <div className="absolute left-[-1rem] top-1/2 -translate-y-1/2 text-8xl font-light uppercase text-off-white">
              PERFECT
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthLayout;
