import React from 'react';
import '@/styles/components/Exercise.scss';
import { useParams } from 'react-router-dom';
import ExerciseSetup from '@/components/ExerciseSetup/ExerciseSetup';

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ExerciseSetupPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.sdk.event().emit('ExercisePanelEntered');
    }, 1000);
  }

  render() {
    return (
      <>
        <input type="hidden" id="fakeAudioSession" value="" />
        <audio
          id="fakeAudioSessionPlayer"
          crossOrigin="anonymous"
          src=""
          playsInline
          style={{ display: 'none' }}></audio>
        <ExerciseSetup
          StepLabel={this.props.match.params.step}
          ExerciseID={this.props.match.params.id}
          RerunNodeID={this.props.match.params.RerunNodeID || null}
          ExerciseSessionID={this.props.match.params.ExerciseSessionID || null}
        />
      </>
    );
  }
}

export default withRouter(ExerciseSetupPage);
