import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ExerciseSetupPage from '@/pages/Exercise/ExerciseSetupPage';
import LegacyFeedbackPage from '@/pages/Feedback/LegacyFeedbackPage';
import ExerciseVisioPage from '@/pages/Exercise/ExerciseVisioPage.jsx';
import LegacyExercisePage from '@/pages/Exercise/LegacyExercisePage';
import Welcome from '@/pages/Welcome/Welcome';
import FeedbackPage from '@/pages/Feedback/FeedbackPage';
import { StepContext } from '@UIKit/Stepper/Stepper';
import Cgu from './Auth/Cgu/Cgu';
import { ExerciseContextProvider } from '@/contexts/ExerciseContext';
import ExerciseBriefingPage from '@/pages/Exercise/ExerciseBriefingPage';

const MainRouting = () => {
  const [activeStepId, setActiveStepId] = useState<string>('ACT-0');

  return (
    <>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/cgu" element={<Cgu />} />
        <Route
          path="/feedback/:exerciseID/:exerciseSessionID/global"
          element={
            <StepContext.Provider value={{ activeStepId, setActiveStepId }}>
              <FeedbackPage />
            </StepContext.Provider>
          }
        />
        <Route path="/exercise/1" element={<LegacyExercisePage exerciseId="1" />} />
        <Route path="/exercise/4" element={<LegacyExercisePage exerciseId="4" />} />
        <Route path="/feedback/:idExercise/:idExerciseSession" element={<LegacyFeedbackPage />} />
        <Route
          path="/exercise/*"
          element={
            <ExerciseContextProvider idExercise={null} idExerciseSession={null}>
              <Routes>
                <Route path=":id" element={<ExerciseSetupPage />} />
                <Route path=":id/briefing" element={<ExerciseBriefingPage />} />
                <Route path=":id/visio" element={<ExerciseVisioPage />} />
                <Route
                  path=":id/:ExerciseSessionID/rerun/:RerunNodeID/briefing"
                  element={<ExerciseBriefingPage />}
                />
              </Routes>
            </ExerciseContextProvider>
          }
        />
      </Routes>
    </>
  );
};

export default MainRouting;
