import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExerciseContextProvider } from '@/contexts/ExerciseContext';
import FeedbackContainer from '@/components/Feedback/LegacyFeedback/FeedbackContainer';

const LegacyFeedbackPage = () => {
  const { idExercise, idExerciseSession } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.sdk.event().emit('ExercisePanelEntered');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ExerciseContextProvider idExercise={idExercise} idExerciseSession={idExerciseSession}>
      <FeedbackContainer />
    </ExerciseContextProvider>
  );
};
export default LegacyFeedbackPage;
