import log from 'loglevel';
import Utils from '../../Utils/Utils';
import NodePort from './Shared/NodePort';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import ExerciseNode from './ExerciseNode';
import SpeechToText from './SpeechToText';
import { SpeechSourceType } from '../ExerciseSessionHistory';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';

// Status enums
const STTStatus = {
  Inactive: Symbol('inactive')
};
const AnalysisStatus = {
  WaitingForFirstWord: Symbol('waitingForFirstWord')
};
const AnalysisResultStatus = {
  Success: 'success',
  FrontendTimeout: 'frontendTimeout',
  STTFailed: 'STTFailed',
  Failed: 'failed'
};

export default class SmartBranchingDecision extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  ForcedSpeechInput = new NodePort('ForcedSpeechInput', 'input', this);
  SpeechStarted = new NodePort('SpeechStarted', 'output', this);
  SpeechEnded = new NodePort('SpeechEnded', 'output', this);
  Failed = new NodePort('Failed', 'output', this);

  // Parameters
  BranchingDecisionName = '';
  STTPhraseList = [];
  STTEndSilence = null;
  Branches = [];
  AvailableUserActions = {};
  AvailableUserActionsFeedbacks = {};
  IgnoreUserActions = false;
  Exceptions = [];
  DefaultUserActionID = null;

  // GPT mode parameters
  GPTMode = false;
  GPT_Prompt = '';
  GPT_GPTEngine = '';
  GPT_MaxTokens = 0;
  GPT_Temperature = 0;
  GPT_TopP = 0;
  GPT_FrequencyPenalty = 0;
  GPT_PresencePenalty = 0;
  GPT_StopSequence = '';

  // Dynamic values
  DatabaseID = '';
  m_BDStartTime = null;
  ForcedSpeechSourceNode = null;

  // Speech to text
  m_InternalSpeechToTextNode = null;
  m_STTStatus = STTStatus.Inactive;
  m_Speech = '';
  m_IntermediateSilenceMs = 700;
  m_LastSpeechReceivedTime = null;

  // Analysis
  m_AnalysisTimeout = 3500;
  m_MinimumFrontendTimeoutEventsToNotify = 1;
  m_BranchDetectionStartTime = null;
  m_BranchDetectionDuration = 0;
  m_UserActionsDetectionStartTime = null;
  m_AnalysisStatus = AnalysisStatus.WaitingForFirstWord;
  m_CurrentBranchRequestController = null;
  m_AnalysisCounter = 0;

  ToastUserActionsToPop = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.BranchingDecisionName = iProperties.BranchingDecisionName
      ? iProperties.BranchingDecisionName
      : '';
    this.AvailableUserActions = iProperties.AvailableUserActions;
    this.AvailableUserActionsFeedbacks = iProperties.AvailableUserActionsFeedbacks;
    this.DefaultUserActionID = iProperties.DefaultUserActionID || null;
    this.IgnoreUserActions = iProperties.IgnoreUserActions;
    this.Exceptions = iProperties.Exceptions ? iProperties.Exceptions : [];
    this.STTPhraseList = iProperties.STTPhraseList ? iProperties.STTPhraseList : [];
    this.STTEndSilence = iProperties.STTEndSilence ? iProperties.STTEndSilence : this.STTEndSilence;

    iProperties.Branches.forEach((branch) => {
      //log.debug(this.GetIdentity() + " constructor: Adding dynamic branch '" + branch.Name + "'.");

      let newBranch = new Branch(branch.ID, branch.Name);
      this.Branches.push(newBranch);

      this[newBranch.GetOutputPortName()] = new NodePort(
        newBranch.GetOutputPortName(),
        'output',
        this
      );
    });

    // GPT mode parameters
    this.GPTMode = iProperties.GPTMode;
    this.GPT_Prompt = iProperties.GPT_Prompt; // Deprecated? No longer used. (For now?)
    this.GPT_GPTEngine = iProperties.GPT_GPTEngine;
    this.GPT_MaxTokens = iProperties.GPT_MaxTokens;
    this.GPT_Temperature = iProperties.GPT_Temperature;
    this.GPT_TopP = iProperties.GPT_TopP;
    this.GPT_FrequencyPenalty = iProperties.GPT_FrequencyPenalty;
    this.GPT_PresencePenalty = iProperties.GPT_PresencePenalty;
    this.GPT_StopSequence = iProperties.GPT_StopSequence;

    // Create an internal Speech To Text node
    const sttProps = {
      ID: iProperties.ID + '.1',
      Type: 'SpeechToText',
      Endpoint: '',
      EndSilenceSeconds: this.STTEndSilence,
      PhraseList: this.STTPhraseList,
      ParentNode: this
    };
    this.m_InternalSpeechToTextNode = new SpeechToText(iGraph, sttProps);
    this.m_InternalSpeechToTextNode.Initialize();

    // Setup multiple requests controller
    this.m_CurrentRequestController = null;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", branches count = " + this.Branches.length + ".");
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);
    // Save this node as the last branching decision node
    this.Graph.SetCurrentBranchingDecision(this, false);

    if (iIsRewindMode) {
      // Make sure the BD activation counter is incremented when rewinding
      this.Graph.ActivatedBranchingDecisionsCount++;
      return;
    }

    // Resets
    this.m_BDStartTime = new Date();
    this.m_STTStatus = 'inactive';
    this.m_Speech = '';
    this.ForcedSpeechSourceNode = null;

    // Disable pause button at start
    window.sdk.event().emit('disablePauseButton');

    this.Graph.IncrementBranchingDecisionsActivations();

    // Get the bot's video names for each branch
    this.Branches.forEach((branch) => {
      branch.VideoName = Utils.GetNextBotVideoAfterPort(
        this[branch.GetOutputPortName()]
      )?.VideoName;
    });

    // Log initialized BranchingDecision to DynamoDB
    let branchingDecision = await window.sdk
      .BranchingDecision()
      .createOne(
        this.Graph.CurrentExerciseSessionID,
        this.m_BDStartTime,
        'initialized',
        this.ID.toString(),
        this.BranchingDecisionName,
        true,
        JSON.stringify(this.Branches)
      );
    this.DatabaseID = branchingDecision.ID;
    log.debug(this.GetIdentity() + '.OnActivated: BranchingDecisionID = ' + this.DatabaseID);

    this.Graph.History.AddBranchingDecisionResult(
      this.ID,
      'chosenBranch',
      this.Graph.GetCurrentSceneName(),
      this.Graph.GetCurrentSceneNodeID(),
      this.DatabaseID
    );

    /*// Test mode: Prevent STT and other analysis tasks to execute and wait for the forced user actions
    if (window.testMode.forceUserActionsMode) {
      this.m_LastSpeechReceivedTime = new Date();
      this.SpeechStarted.Activate();
      this.SpeechEnded.Activate();
      this.m_AnalysisStatus = 'waitingForAnalysisResult';
      this.AnalyzeSpeech('');
      return;
    }*/

    this.m_AnalysisStatus = 'waitingForFirstWord';

    // If activated by the ForcedSpeechInput port, use the speech from caller brancing decision node
    if (iActivationLink.Target.Port && iActivationLink.Target.Port.Name === 'ForcedSpeechInput') {
      // Get the node connected to the ForcedSpeechInput port
      this.ForcedSpeechSourceNode = this.Graph.History.GetPreviouslyActivatedNodeOfType(
        this.m_LatestNodeActivationEventID,
        ['SmartBranchingDecision']
      );
      if (!this.ForcedSpeechSourceNode) {
        log.error(
          this.GetIdentity() + '.OnActivated: No connected node found on ForcedSpeechInput.'
        );
        return;
      }

      // Log the branching decision shortcut event
      this.Graph.History.AddBranchingDecisionShortcut(
        this.ForcedSpeechSourceNode.ID,
        this.ID,
        this.ForcedSpeechSourceNode.DatabaseID,
        this.DatabaseID
      );

      // Get conversation history
      this.m_PreviousConversation = this.Graph.History.GetConversationForBranchingDecision(
        this.ForcedSpeechSourceNode.ID,
        true
      );

      // Get the speech from the connected node
      const forcedUserSpeech = this.ForcedSpeechSourceNode.GetSpeech();
      if (!forcedUserSpeech) {
        log.error(
          this.GetIdentity() +
            '.OnActivated: No speech found on connected node: ' +
            this.ForcedSpeechSourceNode.GetIdentity()
        );
        return;
      }

      // Create the duplicate history event of the forced user speech
      const previousUserSpeechEvent = this.Graph.History.GetUserSpeechByBranchingDecisionDatabaseID(
        this.ForcedSpeechSourceNode.DatabaseID
      );
      this.Graph.History.AddUserSpeech(
        previousUserSpeechEvent.Content.NodeID,
        previousUserSpeechEvent.Content.AnalysisTaskID,
        previousUserSpeechEvent.Content.Speech,
        new Date(),
        this.DatabaseID,
        SpeechSourceType.bdShortcut,
        previousUserSpeechEvent.Content.BeautifiedSpeech
      );

      // Use the speech from the caller branching decision node
      this.OnPartialSpeechDetected(forcedUserSpeech);
      this.OnSpeechDetected(forcedUserSpeech);
    }
    // If activated by the Input port, use the speech from the internal SpeechToText node
    else {
      // Get conversation history
      this.m_PreviousConversation = this.Graph.History.GetConversationForBranchingDecision(this.ID);

      this.StartSpeechToText();
    }

    // Test mode: Prevent STT and other analysis tasks to execute and wait for the forced user actions
    if (window.testMode.forceUserActionsMode) {
      return;
    }
  }

  FindForcedSpeechSourceNode() {
    // Get the smart branching decision node that stimulated this node through the ForcedSpeechInput port
    // We will follow backward the connections to find the source node (first SmartBranchingDecision node found)
    // Since multiple nodes can be connected to the ForcedSpeechInput port, we have to use the ports' memory of last activator port to guess which one is the source node
    let currentNode = this;

    while (currentNode) {
      if (currentNode.ForcedSpeechInput.GetLastActivatorPort()) {
        currentNode = currentNode.ForcedSpeechInput.GetLastActivatorPort().ParentNode;
      } else {
        break;
      }
    }
  }

  // Speech to text
  StartSpeechToText() {
    log.debug(this.GetIdentity() + '.StartSpeechToText: Starting SpeechToText node.');
    this.m_STTStatus = 'started';
    this.m_InternalSpeechToTextNode.OnActivated(
      Utils.CreateActivationLinkFromObjects(
        this,
        null,
        this.m_InternalSpeechToTextNode,
        this.m_InternalSpeechToTextNode.Input
      )
    );
  }

  OnFirstWordDetected() {
    log.debug(this.GetIdentity() + '.OnFirstWordDetected: Activating SpeechStarted output port.');
    this.m_STTStatus = 'firstword';
    this.SpeechStarted.Activate();
  }

  OnPartialSpeechDetected(iSpeech) {
    log.debug(
      this.GetIdentity() +
        '.OnPartialSpeechDetected. DebugDate : ' +
        new Date().getHours() +
        'h:' +
        new Date().getMinutes() +
        'm:' +
        new Date().getSeconds() +
        's:' +
        new Date().getMilliseconds() +
        'ms'
    );
    this.m_LastSpeechReceivedTime = new Date();

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for first word, now waiting for silence.'
        );
        this.m_AnalysisStatus = 'waitingForSilence';
        break;

      case 'waitingForSilence':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for silence, reset silence timer.'
        );
        // Nothing else to do
        break;

      case 'waitingForAnalysisResult':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for analysis result, abort current request and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for analysis result and end of speech, stop analysis and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      case 'waitingForEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for end of speech, stop analysis and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnPartialSpeechDetected: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  OnSpeechDetected(iSpeech) {
    this.m_LastSpeechReceivedTime = new Date();
    this.m_STTStatus = AnalysisResultStatus.Success;
    this.m_Speech = iSpeech;
    this.SpeechEnded.Activate();

    // Specific behavior depending on the mode
    this.OnSpeechDetectedModeSpecific();

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        log.debug(
          this.GetIdentity() + '.OnSpeechDetected: When waiting for first word, should not happen.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        this.AnalyzeSpeech();
        break;

      case 'waitingForSilence':
        log.debug(
          this.GetIdentity() + '.OnSpeechDetected: When waiting for silence, start analysis.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        this.AnalyzeSpeech();
        break;

      case 'waitingForAnalysisResult':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for analysis result, should not happen.'
        );
        // Nothing to do
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for analysis result and end of speech, waiting for analysis result.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        break;

      case 'waitingForEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for end of speech, storing speech and using analysis result.'
        );
        this.UseAnalysisResult();
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnSpeechDetected: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  OnSpeechDetectedModeSpecific() {
    // Specific behavior depending on the mode
  }

  OnSTTFailed() {
    log.debug(this.GetIdentity() + '.OnSTTFailed: Activating Failed output port.');
    this.m_STTStatus = AnalysisResultStatus.Failed;

    this.ActivateFailedOutput(AnalysisResultStatus.STTFailed);
  }

  GetSpeech() {
    return this.m_Speech;
  }

  OnSpeechSegmentation(iSpeech) {
    this.m_Speech = iSpeech;

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for first word.'
        );
        break;

      case 'waitingForSilence':
        this.m_AnalysisStatus = 'waitingForAnalysisResultAndEndOfSpeech';
        this.AnalyzeSpeech();
        break;

      case 'waitingForAnalysisResult':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for analysis result.'
        );
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for analysis result and end of speech.'
        );
        break;

      case 'waitingForEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for end of speech.'
        );
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnSpeechSegmentation: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  // Branch choice analysis
  async AnalyzeSpeech() {
    log.debug(
      this.GetIdentity() +
        '.AnalyzeSpeech: Asking API. DebugDate : ' +
        new Date().getHours() +
        'h:' +
        new Date().getMinutes() +
        'm:' +
        new Date().getSeconds() +
        's:' +
        new Date().getMilliseconds() +
        'ms'
    );

    // Abort current analysis task if running
    if (this.m_CurrentBranchRequestController) {
      log.debug(this.GetIdentity() + '.AnalyzeSpeech: Aborting current request.');
      this.m_CurrentBranchRequestController.abort();
    }

    // Create a new controller for this request
    this.m_CurrentBranchRequestController = new AbortController();

    // Initializations
    this.m_AnalysisCounter++;
    this.m_BranchDetectionStartTime = new Date();

    let result = null;
    try {
      if (window.testMode.forceUserActionsMode) {
        // Test mode: infinitely wait for forced user actions
        result = await this.ExecuteAnalysis();
      } else {
        // Normal node: Execute the analysis with a timeout to prevent infinite waiting
        result = await Utils.executeWithTimeout(
          this.m_AnalysisTimeout, // Timeout in milliseconds
          {
            status: AnalysisResultStatus.FrontendTimeout,
            request: null,
            answer: null,
            branch: null
          }, // Value to return on timeout
          () => this.ExecuteAnalysis() // The function to execute
        );
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        this.m_CurrentBranchRequestController = null;
        return;
      } else {
        log.error(this.GetIdentity() + '.AnalyzeSpeech: Request failed:', error);
        result = {
          status: AnalysisResultStatus.Failed,
          request: null,
          answer: null,
          branch: null
        };
      }
    }

    if (result.status === AnalysisResultStatus.FrontendTimeout) {
      this.handleFrontendTimeout();
    }

    this.m_BranchDetectionDuration =
      new Date().getTime() - this.m_BranchDetectionStartTime.getTime();

    log.debug(this.GetIdentity() + '.AnalyzeSpeech: result = ', result);

    this.OnAnalysisResult(result);
  }

  handleFrontendTimeout() {
    this.Graph.History.AddDetectionIssueEvent(this.ID, AnalysisResultStatus.FrontendTimeout);

    const frontendTimeoutIssueEvents = this.Graph.History.GetDetectionIssueEvents(
      AnalysisResultStatus.FrontendTimeout
    );

    if (frontendTimeoutIssueEvents.length > this.m_MinimumFrontendTimeoutEventsToNotify) {
      this.Graph.Notifier.notify({
        type: GraphEventTypes.DetectionIssue,
        content: { reason: AnalysisResultStatus.FrontendTimeout }
      });
    }

    log.debug(
      `${this.GetIdentity()}.AnalyzeSpeech: Request timed out on frontend side (timeout = ${
        this.m_AnalysisTimeout
      }ms).`
    );
  }

  // To override with mode-specific behavior in child classes
  async ExecuteAnalysis() {}

  OnAnalysisResult(iResult) {
    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        // Should not happen
        log.debug(
          this.GetIdentity() + '.OnAnalysisResult: Should not happen when waiting for first word.'
        );
        break;

      case 'waitingForSilence':
        // Should not happen
        log.debug(
          this.GetIdentity() + '.OnAnalysisResult: Should not happen when waiting for silence.'
        );
        break;

      case 'waitingForAnalysisResult':
        this.m_AnalysisStatus = 'usingAnalysisResult';
        this.m_AnalysisResult = iResult;
        this.UseAnalysisResult();
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnAnalysisResult: Storing analysis result and waiting for end of speech.'
        );
        this.m_AnalysisResult = iResult;
        this.m_AnalysisStatus = 'waitingForEndOfSpeech';
        break;

      case 'waitingForEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnAnalysisResult: Should not happen when waiting for end of speech.'
        );
        break;

      default:
        log.debug(
          this.GetIdentity() + ": Unknown analysis status '" + this.m_AnalysisStatus + "'."
        );
        break;
    }
  }

  StopAnalysisAndWaitForSilence() {
    // Abort current analysis task if running
    log.debug(this.GetIdentity() + '.StopAnalysisAndWaitForSilence: Aborting current request');
    this.m_CurrentBranchRequestController?.abort();
    this.m_CurrentRequestController = null;

    this.m_AnalysisStatus = 'waitingForSilence';
  }

  PushUserActionToast(iUserActionFeedbackID, iIsMissedOpportunity = false) {
    // Create a shallow copy of the feedback object in case it is modified later
    const userActionFeedback = { ...this.AvailableUserActionsFeedbacks[iUserActionFeedbackID] };

    if (iIsMissedOpportunity) {
      userActionFeedback.IsMissedOpportunity = true;
    }

    this.ToastUserActionsToPop.push(userActionFeedback);
  }

  MakeUserActionToastsPop() {
    const toastUserActions = this.FilterToastUserActions();

    // Add popped user actions to history
    this.Graph.History.AddPoppedUserActions(
      this.ID,
      toastUserActions,
      this.Graph.GetCurrentActName(),
      this.Graph.LastBranchingDecisionNode.DatabaseID
    );

    window.sdk.event().emit('popUserActionsToasts', toastUserActions);
    this.ToastUserActionsToPop = [];
  }

  FilterToastUserActions() {
    // Only push one toast: the one with the highest priority
    this.ToastUserActionsToPop.sort((a, b) => b.PriorityRank - a.PriorityRank);
    return this.ToastUserActionsToPop.slice(0, 1);
  }

  UseAnalysisResult() {
    // Compute time between last speech and bot video trigger
    let timeBetweenLastSpeechAndBotVideoTrigger = 0;
    if (this.m_LastSpeechReceivedTime) {
      timeBetweenLastSpeechAndBotVideoTrigger =
        new Date().getTime() - this.m_LastSpeechReceivedTime.getTime();
    }

    // If the request failed, activate the failed output port
    if (this.m_AnalysisResult.status !== AnalysisResultStatus.Success) {
      log.debug(
        this.GetIdentity() +
          '.UseAnalysisResult: Request failed. Result = ' +
          this.m_AnalysisResult.branch?.Name +
          '.\n Analysis requests sent = ' +
          this.m_AnalysisCounter +
          '.\n Speech used = ' +
          this.m_AnalysisResult.request?.input +
          '.\n Time between last speech and bot video trigger = ' +
          timeBetweenLastSpeechAndBotVideoTrigger +
          'ms.'
      );

      this.ActivateFailedOutput(this.m_AnalysisResult.status);
      return;
    }

    log.debug(
      this.GetIdentity() +
        '.UseAnalysisResult: final branch = ' +
        this.m_AnalysisResult.branch?.Name +
        '.\n Analysis requests sent = ' +
        this.m_AnalysisCounter +
        '.\n Time between last speech and bot video trigger = ' +
        timeBetweenLastSpeechAndBotVideoTrigger +
        'ms.'
    );

    // Log new STT technique results to DynamoDB
    window.sdk.usersActivity().createOne('FasterBranchingDecisionInfo', {
      BranchingDecisionNodeID: this.DatabaseID,
      BranchingDecisionID: this.ID.toString(),
      AnalysisCounter: this.m_AnalysisCounter,
      TimeBetweenLastSpeechAndBotVideoTrigger: timeBetweenLastSpeechAndBotVideoTrigger
    });

    // Log API result to DynamoDB
    this.LogAnalysisResultToDynamoDB(this.m_AnalysisResult);

    // Detect user actions if GPT mode
    this.UseAnalysisResultModeSpecific();

    // Notify debug values that the node is done
    this.Graph.SetCurrentBranchingDecision(this, true);

    // If no branch found, activate the failed output port
    if (!this.m_AnalysisResult.branch) {
      log.debug(
        `${this.GetIdentity()}.UseAnalysisResult: No branch found, activating failed output port.`
      );
      this.ActivateFailedOutput('branchNotFound');
    } else {
      log.debug(
        `${this.GetIdentity()}.UseAnalysisResult: Activating output port '${
          this.m_AnalysisResult.branch.Name
        }.`
      );
      this.ActivateBranchOutput(this.m_AnalysisResult.branch);
    }
  }

  UseAnalysisResultModeSpecific() {
    // Specific behavior depending on the mode
  }

  // eslint-disable-next-line
  async LogAnalysisResultToDynamoDB(iResult) {
    // Specific behavior depending on the mode
  }

  async FinalizeBranchingDecisionToDynamoDB(iChosenBranch, iError = null) {
    let status = iError ? iError : 'raw';

    // Log branching decision result to DynamoDB
    window.sdk
      .BranchingDecision()
      .updateItem(this.Graph.CurrentExerciseSessionID, this.DatabaseID, {
        DecisionStatus: status,
        ChosenBranch: iChosenBranch
      });
  }

  // Test mode: force user actions and wait for them
  // eslint-disable-next-line
  ForceUserActions(iUserActionIDs) {
    // Specific behavior depending on the mode
  }

  // Node methods
  async ActivateBranchOutput(iBranch) {
    this.Reset();
    this.FinalizeBranchingDecisionToDynamoDB(JSON.stringify(iBranch));

    // If not GPT mode, we do not evaluate the acts, we just activate the branch output
    if (!this.GPTMode) {
      this[iBranch.GetOutputPortName()].Activate();
      return;
    }

    const shouldEvaluateAct = Utils.ShouldEvaluateActAfterBranchingDecision(
      this[iBranch.GetOutputPortName()]
    );

    if (shouldEvaluateAct) {
      this.Graph.CurrentActNode.OnActEvaluation();
    }

    this[iBranch.GetOutputPortName()].Activate();
  }

  async ActivateFailedOutput(iReason) {
    this.Reset();
    this.FinalizeBranchingDecisionToDynamoDB('Failed', iReason);
    this.Failed.Activate();
  }

  Pause() {
    super.Pause();

    if (this.IsActive()) {
      // Pause the internal SpeechToText node
      this.m_InternalSpeechToTextNode.Pause();
    }
  }

  Resume() {
    super.Resume();

    if (this.IsActive()) {
      // Resume the internal SpeechToText node
      this.m_InternalSpeechToTextNode.Resume();
    }
  }

  Reset() {
    super.Reset();

    // Reset the internal SpeechToText node
    this.m_InternalSpeechToTextNode.Reset();

    // Reset state variables
    this.m_STTStatus = 'inactive';
    this.m_AnalysisStatus = 'waitingForFirstWord';
    this.m_CurrentBranchRequestController?.abort();
    this.m_CurrentRequestController = null;
    this.m_LastSpeechReceivedTime = null;

    // Re-enable pause button when finished
    window.sdk.event().emit('enablePauseButton');
  }

  // Create a list of possible user actions
  GetCurrentlyPossibleUserActions() {
    let possibleUserActions = [];

    for (let key in this.AvailableUserActions) {
      possibleUserActions.push({
        ID: key,
        Number: this.Graph.AvailableUserActions[key].UserActionNumber,
        PromptName: this.Graph.AvailableUserActions[key].PromptName
      });
    }

    return possibleUserActions;
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }

  //////////////////////////
  // Test functions
  //////////////////////////

  TestExecute(iActivationLink, iTestReport) {
    // Start the test
    if (!iActivationLink.Target.Port || iActivationLink.Target.Port.Name === this.Input.Name) {
      // Test-activate Analysis process
      this.Analysis.TestActivateAllConnections(iTestReport);
    } else {
      let chosenBranch = this.GetBranchFromInput(iActivationLink.Target.Port);

      // Fill the test report
      iTestReport['ChosenBranch'] = chosenBranch;
    }
  }
}

class Branch {
  ID = -1;
  Name = '';

  constructor(iID, iName) {
    this.ID = iID;
    this.Name = iName;
  }

  GetOutputPortName() {
    return 'Branch' + this.ID;
  }

  ToString() {
    return `{
  Name: '${this.Name}'
  OutputPort: '${this.GetOutputPortName()}'
}`;
  }
}
