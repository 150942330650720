import log from 'loglevel';
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotRandomVideo extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  BotVideos = [];

  // Internal values
  ChosenBot = null;
  ChosenBotVideo = null;
  Transcript = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.PreventSpeakingFrame = iProperties.PreventSpeakingFrame;

    iProperties.BotVideos.forEach((botVideo) => {
      /*log.debug(
        this.GetIdentity() +
          " constructor: Adding dynamic botVideo '" +
          botVideo.BotName +
          '-' +
          botVideo.Video +
          "'."
      );*/

      let newBotVideo = new BotVideo(
        botVideo.ID,
        botVideo.BotName,
        botVideo.Video,
        botVideo.Probability
      );
      this.BotVideos.push(newBotVideo);

      this[newBotVideo.GetOutputPortName()] = new NodePort(
        newBotVideo.GetOutputPortName(),
        'output',
        this
      );
    });

    // Normalize the botVideoes probas
    let probasSum = this.BotVideos.reduce((total, botVideo) => total + botVideo.Probability, 0);
    this.BotVideos.forEach((botVideo) => (botVideo.Probability /= probasSum));
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // Randomly choose among the available botVideoes, considering their probabilities and using the graph random seed
    let randomValue = this.Graph.GenerateRandomValue(); // Instead of Math.random() to be able to set the seed
    log.debug(this.GetIdentity() + ' randomValue = ' + randomValue);

    // Choose the botVideo
    let probasSum = 0;
    for (let i = 0; i < this.BotVideos.length; i++) {
      //log.debug(this.GetIdentity() + " probasSum = " + probasSum + ", randomValue = " + randomValue + ", this.BotVideos[i].Probability = " + this.BotVideos[i].Probability);
      probasSum += this.BotVideos[i].Probability;
      if (randomValue <= probasSum) {
        this.ChosenBotVideo = this.BotVideos[i];
        break;
      }
    }

    log.debug(
      this.GetIdentity() +
        " Bot '" +
        this.ChosenBotVideo.BotName +
        "' will play the video '" +
        this.ChosenBotVideo.Video +
        "'..."
    );

    // Get the bot
    this.ChosenBot = ParticipantsModule.Instance.GetBot(this.ChosenBotVideo.BotName);

    if (this.ChosenBot) {
      // Display automatically the speaking frame if allowed
      if (!this.PreventSpeakingFrame) {
        this.ChosenBot.setSpeakingState('speaking');
      }

      // Play the video
      this.ChosenBot.setConnectionState('connected');
      this.ChosenBot.PlayVideo(this.ChosenBotVideo.Video, {
        triggerTime: 0,
        onTimeTriggered: () => {},
        onEnded: () => {
          this.OnActionFinished();
        }
      });

      // Get bot transcript from database
      await this.GetVideoTranscript();

      // Create auto subtitles if needed
      if (window.testMode.autoSubs) {
        this.CreateSubtitles();
      }

      // Log action to history
      this.LogToHistory();
    } else {
      log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' not found!");
    }
  }

  async GetVideoTranscript() {
    // Get the transcript each time the node is activated since it randomly changes the target video
    const videoInfos = await window.sdk.BotVideo().getOne(this.ChosenBotVideo.Video);

    if (videoInfos.state === 'success') {
      this.Transcript = videoInfos.transcript;
    } else {
      log.debug(
        this.GetIdentity() +
          '.GetVideoTranscript: Error: transcript not found. Message: ' +
          (videoInfos.info?.message || 'No message available')
      );
    }
  }

  async CreateSubtitles() {
    let subtitles = this.Transcript;

    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/auto-translate', {
        body: {
          language: window.testMode.autoSubsLanguage,
          textToTranslate: this.Transcript,
          exerciseSessionID: this.sdk.exerciseSessionID
        }
      });
      log.debug(this.GetIdentity() + '.CreateSubtitles: translation = ', gptAnswer.translation);

      subtitles = gptAnswer.translation;
    } catch (error) {
      log.error(this.GetIdentity() + `.CreateSubtitles: ${error}`);
    }

    this.ChosenBot.SetSubtitles(subtitles);
  }

  async LogToHistory() {
    this.Graph.History.AddBotSpeech(
      this.ID,
      this.ChosenBotVideo.BotName,
      this.ChosenBotVideo.Video,
      this.Transcript
    );
  }

  Pause() {
    if (this.ChosenBot && this.IsActive()) this.ChosenBot.Pause();
  }

  Resume() {
    if (this.ChosenBot && this.IsActive() && this.Graph.IsRunning()) this.ChosenBot.Resume();
  }

  FreezeSystem() {
    if (this.ChosenBot && this.IsActive()) this.ChosenBot.Pause();
  }

  UnfreezeSystem() {
    if (this.ChosenBot && this.IsActive() && this.Graph.IsRunning()) this.ChosenBot.Resume();
  }

  Skip() {
    if (!this.IsActive()) {
      return;
    }

    log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' skip.");

    // Hide automatically the speaking frame if allowed
    if (
      !this.PreventSpeakingFrame &&
      this.ChosenBot &&
      !Utils.WillBotContinueTalking(this.Output, this.ChosenBotVideo.BotName)
    ) {
      this.ChosenBot.setSpeakingState('no');
    }

    // Make the video bot stop current action and return to wait loop
    this.ChosenBot.ReturnToWaitLoop();

    // Activate Outputs and stop the node
    this.SetActive(false);
    this.Output.Activate();
    this[this.ChosenBotVideo.GetOutputPortName()].Activate();
  }

  OnActionFinished() {
    if (!this.IsActive()) {
      log.debug(
        this.GetIdentity() +
          " Bot '" +
          this.ChosenBotVideo.BotName +
          "' finished its video play action, but ignored since not active."
      );
      return;
    }

    log.debug(
      this.GetIdentity() +
        " Bot '" +
        this.ChosenBotVideo.BotName +
        "' finished its video play action."
    );

    // Hide automatically the speaking frame if allowed
    if (
      !this.PreventSpeakingFrame &&
      this.ChosenBot &&
      !Utils.WillBotContinueTalking(this.Output, this.ChosenBotVideo.BotName)
    ) {
      this.ChosenBot.setSpeakingState('no');
    }

    // Activate Output
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
    this[this.ChosenBotVideo.GetOutputPortName()].Activate();
  }

  HasVideosForBot(iBotName) {
    return this.BotVideos.some((botVideo) => botVideo.BotName === iBotName);
  }

  GetDetailedIdentity() {
    let detailedIdentity = super.GetDetailedIdentity();

    if (this.ChosenBotVideo) {
      detailedIdentity += ` : ${this.ChosenBotVideo.BotName} - ${this.ChosenBotVideo.Video}`;
    }

    return detailedIdentity;
  }

  PrintParameters() {
    //log.debug("BotName = " + this.ChosenBotVideo.BotName + ", VideoName = " + this.ChosenBotVideo.Video);
  }
}

class BotVideo {
  ID = -1;
  BotName = '';
  Video = '';
  Probability = 1;

  constructor(iID, iBotName, iVideo, iProbability) {
    this.ID = iID;
    this.BotName = iBotName;
    this.Video = iVideo;
    this.Probability = iProbability;
  }

  GetOutputPortName() {
    return 'BotVideo' + this.ID;
  }

  ToString() {
    return `{
  BotName: '${this.BotName}'
  Video: '${this.Video}'
  OutputPort: '${this.GetOutputPortName()}'
  Probability: '${this.Probability}'
}`;
  }
}
