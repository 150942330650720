import React, { useContext, useState } from 'react';
import cameraIll from '@/assets/images/exercise/camera.svg';
import headphoneIll from '@/assets/images/exercise/headphone.svg';
import microphoneIll from '@/assets/images/exercise/microphone.svg';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';

const Equipment = () => {
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);
  const [equipementStepActive, setEquipementStepActive] = useState(0);

  const step = ['intro', 'list'];

  const next = () => {
    let nextStepIndex = equipementStepActive + 1;
    if (!step[nextStepIndex]) setStep('authorization');
    else setEquipementStepActive(equipementStepActive + 1);
  };

  const content = () => {
    switch (step[equipementStepActive]) {
      case 'list':
        return (
          <>
            <h2 className="text-center title">
              <Translation keyName="equipment.material">
                Voici le matériel dont vous allez avoir besoin pour suivre ce parcours :
              </Translation>
            </h2>
            <ul className="-mx-3 mt-6 flex w-[calc] flex-wrap justify-center p-0 text-center">
              <li className="mx-3 w-56">
                <img
                  className="mx-auto size-24 object-contain"
                  src={headphoneIll}
                  width="125"
                  height="126"
                />
                <p className="m-0 text-base">
                  <Translation keyName="equipment.headphones">Un casque</Translation>
                </p>
                <p className="m-0 text-sm font-semibold">
                  <Translation keyName="equipment.listening">
                    (ou autre système d'écoute)
                  </Translation>
                </p>
              </li>
              <li className="mx-3 w-56">
                <img
                  className="mx-auto size-24 object-contain"
                  src={microphoneIll}
                  width="125"
                  height="133"
                />
                <p className="text-base">
                  <Translation keyName="equipment.mic">Un micro</Translation>
                </p>
              </li>
              <li className="mx-3 w-56">
                <img
                  className="mx-auto size-24 object-contain"
                  src={cameraIll}
                  width="125"
                  height="125"
                />
                <p className="text-base">
                  <Translation keyName="equipment.cam">Une caméra</Translation>
                </p>
              </li>
            </ul>
          </>
        );
      case 'intro':
        return (
          <h2 className="text-center title-lg">
            <Translation keyName="equipment.before_start">
              Avant de démarrer, prenons un court instant pour paramétrer vos appareils audio et
              vidéo.
            </Translation>
          </h2>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mx-auto max-w-row">{content()}</div>
      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'materiel'}>
        <button id="equipment-button" class="cta cta--accent" onClick={next}>
          <Translation keyName="general.next">Suivant</Translation>
        </button>
      </BottomBar>
    </>
  );
};

export default Equipment;
