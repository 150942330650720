import { faMicrophoneSlash, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarName } from '@UIKit/AvatarName/AvatarName';
import React from 'react';
import log from 'loglevel';
import Lottie from 'lottie-react';
import raisingHandAnimation from '@/assets/animations/RaisingHand';
import speechAnimation from '@/assets/animations/Speech';
import waitingAnimation from '@/assets/animations/Waiting';
import pictoConnectiong from '@/assets/icons/picto-connecting.png';
import Animation from '@/components/Utilities/Animation';

export default class ParticipantVideoSlot extends React.Component {
  // References
  Participant = null;
  type = null;

  constructor(props, iParticipant) {
    log.debug('ParticipantVideoSlot:constructor', iParticipant.getName(), iParticipant.getID());

    super(props);

    this.Participant = iParticipant;

    this.state = {
      connection: this.Participant.getConnectionState(),
      mic: 'on',
      cam: 'on',
      speaking: this.Participant.getSpeakingState()
    };

    this.Participant.on('stateChange', this.onStateChange);

    log.debug('onStateChange constructor', this.Participant.getName(), this.Participant.on);
  }

  onStateChange = ({ state, value }) => {
    log.debug('onStateChange listener', state, value);
    this.setState({
      [state]: value
    });
  };

  async componentDidMount() {}

  componentWillUnmount() {
    this.Participant.removeListener('stateChange', this.onStateChange);
  }

  renderVideo() {
    log.debug('ParticipantVideoSlot:renderVideo', this.Participant.getID());

    return (
      <div className="wrapper relative overflow-hidden rounded-2.5xl bg-white p-[28.169%_50%]">
        Empty
      </div>
    );
  }

  renderNoCamera() {
    return (
      <div className="wrapper relative overflow-hidden rounded-2.5xl bg-white p-[28.169%_50%]">
        <img
          src={pictoConnectiong}
          width={35}
          height={33}
          className="absolute h-auto w-8 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    );
  }

  renderNotConnected() {
    return (
      <div className="wrapper relative overflow-hidden rounded-2.5xl bg-white p-[28.169%_50%]">
        <div className="absolute -translate-x-1/2 -translate-y-1/2">
          <Animation type={'bulles'} classCSS="video-pending w-8" autoplay={true} loop={true} />
        </div>
      </div>
    );
  }

  render() {
    const { connection, mic, cam, speaking } = this.state;
    let classes = [];
    let icon = null;
    let dataTestId = null;

    if (speaking === 'speaking') {
      // Speaker actuel
      classes.push('participant--speaking');
      icon = <Lottie animationData={speechAnimation} />;

      // Add data-testid when the participant is speaking
      dataTestId = this.type === 'bot' ? 'bot-speaking' : 'user-speaking';
    } else if (speaking === 'raising') {
      // Leve la main
      classes.push('participant--raising');
      icon = <Lottie animationData={raisingHandAnimation} />;
    } else if (speaking === 'readyforSpeaking') {
      classes.push('participant--waiting');
      icon = <Lottie animationData={waitingAnimation} />;
    }

    if (this.type === 'bot') {
      icon = null;
    }

    let content = this.renderVideo();
    if (connection === 'connecting') {
      content = this.renderNotConnected();
      classes.push('participant--not-connected');
    } else if (cam === 'off') {
      classes.push('participant--no-camera');
      content = this.renderNoCamera();
    }

    return (
      <div
        className={`relative ${
          'participant_' + this.Participant.getID() + ' ' + classes.join(' ')
        }`}
        data-testid={dataTestId}>
        {icon && (
          <p className="absolute right-4 top-4 z-1 size-8 rounded-full bg-white p-1 text-speak [&_svg]:h-auto [&_svg]:w-6 [&_svg_path]:fill-current [&_svg_path]:stroke-current [&_svg_rect]:fill-current">
            {icon}
          </p>
        )}

        {content}

        {connection !== 'connecting' && (
          <AvatarName className="name absolute bottom-4 left-4 z-10">
            {this.Participant.getName()}

            {mic === 'off' && <FontAwesomeIcon icon={faMicrophoneSlash} />}
            {cam === 'off' && <FontAwesomeIcon icon={faVideoSlash} />}
          </AvatarName>
        )}
      </div>
    );
  }
}
