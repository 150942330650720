import React, { useContext, useEffect, useState } from 'react';
import PauseMenu from '@/components/PauseMenu/PauseMenu';
import log from 'loglevel';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ExerciseContext } from '@/contexts/ExerciseContext';
import OneToOne from '@/components/VisioExercise/Scenario/OneToOne';
import FeedbacksLoader from '@/components/Feedback/FeedbacksLoader';
import { useExerciseNavigation } from '@/hooks/useExerciseNavigation';
import { usePingBackend } from '@/hooks/usePingBackend';
import TopBar from '@/components/TopBar/TopBar';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import Stepper from '@/components/NewUIKit/Stepper/Stepper';
import DebugPanel from '@/components/VisioExercise/DebugPanel';

const ExerciseVisioPage = () => {
  const {
    ExerciseID,
    jsonGraph,
    ExerciseGraph,
    setExerciseStarted,
    exerciseStarted,
    setIsDetectionIssueModalOpen,
    isDetectionIssueModalOpen,
    actsCompletion,
    setExerciseID,
    isFeedbacksLoading,
    setStep,
    resetExerciseContext
  } = useContext(ExerciseContext);
  const navigate = useNavigate();
  const id = useParams().id;

  const { quitExercise, restartExercise } = useExerciseNavigation(ExerciseID);
  const [pingStatus, pingControls] = usePingBackend();
  const [speechFeedbackText, setSpeechFeedbackText] = useState('');
  const [pauseButtonDisabled, setPauseButtonDisabled] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (!pingStatus.healthy) {
      ExerciseGraph.Pause(false);

      window.sdk.usersActivity().createOne('pingNotHealthy', {
        ExerciseSessionID: ExerciseGraph.CurrentExerciseSessionID,
        Latency: pingStatus.latency
      });

      pingControls.stop();
    }
  }, [pingStatus.healthy]);

  useEffect(() => {
    if (id && id !== ExerciseID) {
      setExerciseID(id);
    }

    window.sdk.event().on('setSpeechFeedbackText', (iText) => {
      if (iText !== '') {
        setSpeechFeedbackText('"' + iText + '"');
      } else {
        setSpeechFeedbackText('');
      }
    });

    window.sdk.event().on('disablePauseButton', () => {
      setPauseButtonDisabled(true);
    });

    window.sdk.event().on('enablePauseButton', () => {
      setPauseButtonDisabled(false);
    });

    window.sdk.event().on('restartExercise', () => {
      resetExercise();
    });

    window.sdk.event().on('goToSettings', () => {
      goToSettings();
    });

    window.sdk.event().on('userLogout', () => {
      ExerciseGraph.Pause(false);
      window.sdk.videoconf().mediaDevices().stop();
    });

    window.sdk.event().on('endExercise', (data) => {
      navigate(`/feedback/${ExerciseID}/${data.exerciseSessionID}/global`, { replace: false });
    });

    return () => {
      pingControls.stop();
    };
  }, []);

  useEffect(() => {
    startScenario();
  }, [jsonGraph, ExerciseGraph]);

  const stopExercise = () => {
    setIsDetectionIssueModalOpen(false);
    quitExercise({});
  };

  const resetExercise = async () => {
    window.sdk.event().emit('modalClose');
    ExerciseGraph.History.AddEvent('Restart', {});
    ExerciseGraph.Stop(true);
    window.sdk.refreshingExercise = true;
    resetExerciseContext();
    restartExercise();
  };

  const goToSettings = () => {
    setExerciseStarted(false);
    resetExerciseContext();
    setStep('settings');

    navigate(`/exercise/${id}/`, { replace: false });
  };

  const startScenario = async () => {
    window.sdk.event().emit('enterExercise');
    await start();
    setExerciseStarted(true);

    log.debug('Exercise.startScenario: Ready, starting!');
  };

  const start = async () => {
    // Start initializations
    log.debug('+++ Exercise Start +++');
    document.body.classList.add('exercise--start');
    // Start the exercise graph
    if (ExerciseGraph) {
      await ExerciseGraph.Start();
      window.sdk.event().emit('startExercise');
    }
  };

  const hideCam = () => {
    // Log to database
    if (ExerciseGraph)
      ExerciseGraph.History.AddEvent('HideCam', {
        State: hide ? 'Visible' : 'Hidden'
      });

    // Apply the change
    setHide(!hide);
    window.sdk.videoconf().toggleWebcamHide();
  };

  const pauseExercise = () => {
    window.sdk.event().emit('pauseExercise');
    window.sdk.event().emit('pause', true);
  };

  const PauseMenuModal = () => {
    const shouldShowModal = isDetectionIssueModalOpen || !pingStatus.healthy;
    const modalReason = isDetectionIssueModalOpen ? 'sttFailed' : 'pingNotHealthy';

    if (!shouldShowModal) {
      return <></>;
    }

    return (
      <PauseMenu
        reason={modalReason}
        fancyFunctions={{
          restart: resetExercise,
          stop: stopExercise
        }}
      />
    );
  };

  return (
    <div>
      <TopBar className="sticky left-0 top-0 z-50 w-full" areButtonsHidden={false} />
      <div className="centered-wide-row flex min-h-[calc(100vh-theme(spacing.12))] flex-col py-8">
        <DebugPanel />
        <div className="exercise__main flex flex-1 flex-col">
          <div className="my-auto">
            {exerciseStarted && (
              <>
                <OneToOne />
                <PauseMenuModal />
              </>
            )}

            {isFeedbacksLoading && <FeedbacksLoader />}
          </div>
        </div>
        {!isFeedbacksLoading && (
          <BottomBar
            className="exercise__bottom-bar"
            pauseExercise={pauseExercise}
            speechFeedback={speechFeedbackText ? speechFeedbackText : ''}
            step={'scenario'}
            pauseButtonDisabled={pauseButtonDisabled}
            hideCam={hideCam}
            hide={hide}>
            <Stepper
              className="mt-4 w-full sm:px-6"
              isExerciseRunning={true}
              initialSteps={actsCompletion}
            />
          </BottomBar>
        )}
        <p className="mt-4 text-xs">
          Practicio {window.infoVersion.version}{' '}
          {window.sdk._env !== 'prod' && window.sdk.exerciseSessionID
            ? `- SessionID: ${window.sdk.exerciseSessionID}`
            : ''}
        </p>
      </div>
    </div>
  );
};

export default ExerciseVisioPage;
