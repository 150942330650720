import React from 'react';
import ParticipantsModule from '@/AppClasses/Participants/ParticipantsModule';
import Bot from '../Bot';
import LocalUser from '../LocalUser';
import { uniqBy } from '@/AppClasses/Utils/uniqBy';

const Mosaique = () => {
  let participantsVideos = uniqBy(ParticipantsModule.Instance.m_Bots, 'Name').map((participant) => (
    <li key={participant.ID} className="m-3 [&_.wrapper]:w-[426px]">
      <Bot bot={participant} />
    </li>
  ));

  uniqBy(ParticipantsModule.Instance.m_Humans, 'Name').map((participant) =>
    participantsVideos.push(
      <li key={participant.ID} className="m-3 [&_.wrapper]:w-[426px]">
        <LocalUser human={participant} />
      </li>
    )
  );
  return <ul className="-m-3 flex flex-wrap justify-center pl-0" data-testid="layout-mosaique-videos">{participantsVideos}</ul>;
};

export default Mosaique;
