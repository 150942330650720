import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class IncrementCounters extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  CountersNames = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.CountersNames = iProperties.CountersNames;
    log.debug(
      `${this.GetIdentity()} constructor: graph = ${this.Graph.ExerciseName}, id = ${
        this.ID
      }, CountersNames = ${JSON.stringify(this.CountersNames)}`
    );
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.Graph.Counters.IncrementCounters(this.CountersNames);
    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }
}
